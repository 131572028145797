import { Box } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ApiContext } from "../../Utils/ApiContext";
import { FieldSlots } from "./FieldSlots";
import { useHorizontalScroll } from "../../Utils/useHorizontalScroll";
import TimeArrayBackground from "./TimeArrayBackground";

const slotHeight = 50;

const minWidth = 100;

export const Timetable = ({
  selectedClass,
  location,
  selectedDate,
  reservationClicked,
  selectedReservations,
  skipPhase,
}) => {
  const { ApiCall } = useContext(ApiContext);
  const scrollRef = useHorizontalScroll();

  const [reservables, setReservables] = useState([]);

  const [timeArray, setTimeArray] = useState([]);

  const [minimum_reservation, setMinimum_reservation] = useState(1440);
  const [loading, setLoading] = useState(true);

  const widthRef = useRef(null);
  const contentWidth = widthRef.current?.offsetWidth + 25; // properly gets real width of timetable grid when 100% width would be too small

  function fetchReservables() {
    setLoading(true);
    ApiCall(
      "GET",
      "location/get_reservables/" +
        location.location_id +
        "?type=" +
        selectedClass.id,
      null
    )
      .then((res) => {
        const temp_filtered = res.filter((r) => r.type !== 2); // Filter out long term reservations

        setReservables(temp_filtered);

        if (temp_filtered.length === 0) {
          skipPhase(1); // Skip to product selection if no reservables are found
        } else if (
          temp_filtered.every(
            (r) => r.open_hours.from_time === r.open_hours.to_time // since backend returns current day + T12:00:00Z as default for reservables without recipes, we check all received reservables and if none of them have different from_time and to_time, we skip phase
          )
        ) {
          if (!location.has_reservations && location.has_products) skipPhase(1); // Skip to product selection if from_time and to_time are the same. This is a temporary fix for locations without recipes
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function calculateMinutesFromMidnight(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return hours * 60 + minutes;
  }

  useEffect(() => {
    fetchReservables();
  }, [selectedClass]);

  function calculateTimeArray() {
    let smallestGap = 1440;
    // Earlist time, minutes from midnight
    let earliestTime = 1440;
    // Latest time, minutes from midnight
    let latestTime = 0;

    if (reservables.length !== 0) {
      for (let i = 0; i < reservables.length; i++) {
        const minimum_reservation =
          reservables[i]?.open_hours?.minimum_reservation;

        // console.log(minimum_reservation);

        if (minimum_reservation > 0 && minimum_reservation < smallestGap) {
          // fix for invalid minimum_reservation since long term reservations are rendered on web booking
          smallestGap = minimum_reservation;
        }
        const from_time = new Date(reservables[i].open_hours.from_time);
        const fromMinutesFromMidnight = calculateMinutesFromMidnight(from_time);

        if (fromMinutesFromMidnight < earliestTime) {
          earliestTime = fromMinutesFromMidnight;
        }

        const to_time = new Date(reservables[i].open_hours.to_time);
        const toMinutesFromMidnight = calculateMinutesFromMidnight(to_time);
        if (toMinutesFromMidnight > latestTime) {
          latestTime = toMinutesFromMidnight;
        }
      }
      setMinimum_reservation(smallestGap);
    }

    if (latestTime < earliestTime) {
      latestTime = 1440;
    }
    let finished = false;
    const timeArrayTemp = [];

    let nextTime = earliestTime;

    // console.log(smallestGap, earliestTime, latestTime);
    // console.log(nextTime);

    while (!finished) {
      timeArrayTemp.push(nextTime);
      nextTime += smallestGap;
      if (nextTime >= latestTime) {
        timeArrayTemp.push(nextTime);
        finished = true;
      }
    }

    setTimeArray(timeArrayTemp);
  }

  useEffect(() => {
    calculateTimeArray();
  }, [reservables]);

  return (
    <div
      style={{
        display: "flex",
        left: 0,
      }}
    >
      {!loading && (
       <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              opacity: 1,
              width: "100vw",
              maxWidth: "1200px",
              height: slotHeight * timeArray.length,
              paddingBottom: "120px",
              position: "relative",
              overflowX: "scroll",
              overflowY: "hidden",
              cursor: "grab",
              userSelect: "none",
              touchAction: "pan-y pan-x",
              willChange: "scroll-position",
              WebkitOverflowScrolling: "touch",
            }}
            ref={scrollRef}
            onMouseDown={(e) => {
              // add support for vertical dragging here too if needed

              e.preventDefault();
              const startX = e.pageX - scrollRef.current.offsetLeft;
              const scrollLeft = scrollRef.current.scrollLeft;

              const handleMouseMove = (e) => {
                const x = e.pageX - scrollRef.current.offsetLeft;
                const walk = (x - startX) * 2; // Adjust the scroll speed here
                scrollRef.current.scrollLeft = scrollLeft - walk;
              };

              document.addEventListener("mousemove", handleMouseMove, { passive: true });
              document.addEventListener("mouseup", () => {
                document.removeEventListener("mousemove", handleMouseMove, { passive: true });
              });
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                // left vertical blur bar
                style={{
                  minWidth: "20px",
                  minHeight: slotHeight * timeArray.length + 60 + "px",
                  position: "fixed",
                  backgroundImage:
                    "linear-gradient(to left, rgba(255,255,255,0), rgba(255,255,255, 1) 90%)",
                  left: 0,
                  top: 0,
                  zIndex: 8,
                }}
              />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "calc(100% - 45px)",
                }}
              >
                <Box style={{ height: "100px" }} />
                <Box
                  style={{
                    height: slotHeight * timeArray.length + "px",
                    width:
                      contentWidth,
                    position: "absolute",
                    top: "100px",
                    boxSizing: "border-box",
                  }}
                >
                  {timeArray.length !== 0 &&
                    timeArray.map((slot, i) => (
                      <TimeArrayBackground
                        slot={slot}
                        first={timeArray[0]}
                        key={i}
                        i={i}
                        minimum_reservation={minimum_reservation}
                        reservables={reservables}
                        slotHeight={slotHeight}
                        minWidth={minWidth}
                      />
                    ))}
                </Box>

                <Box
                  ref={widthRef}
                  sx={{
                    flexDirection: "row",
                    display: "flex",
                    position: "absolute",
                    top: "0px",         
                    marginLeft: "60px",
                    paddingRight: "40px",
                    minWidth: `calc(100% - 40px - 60px)`,
                  }}
                >
                  {reservables.map((field) => {
                    return (
                      <FieldSlots
                        field={field}
                        selectedDate={selectedDate}
                        key={field.id}
                        location={location}
                        smallestGap={minimum_reservation}
                        startingTime={timeArray[0]}
                        slotHeight={slotHeight}
                        totalFields={reservables.length}
                        reservationClicked={reservationClicked}
                        selectedReservations={selectedReservations}
                        minWidth={minWidth}
                      />
                    );
                  })}
                </Box>
              </Box>

              <div
                // right vertical blur bar
                style={{
                  minWidth: "20px",
                  minHeight: slotHeight * timeArray.length + 60 + "px",
                  position: "fixed",
                  backgroundImage:
                    "linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255, 1) 90%)",
                  left: "calc(100vw - 20px)",
                  top: 0,
                  zIndex: 8,
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
};
