import {
  Alert,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useState } from "react";
import { ApiContext } from "../../Utils/ApiContext";

import { ColorScheme } from "../../Utils/ColorScheme";
import TextLogo from "../../Assets/Tolotech_logo_new.png";

const Login = ({ passLogin }) => {
  const { ApiCall } = useContext(ApiContext);
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showRegister, setShowRegister] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [authcode, setAuthcode] = useState("");
  const [showRecovery, setShowRecovery] = useState(false);
  const [showError, setShowError] = useState(false);

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return !re.test(email);
  };

  const deleteAllCookies = () => {
    let cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++)
      // let cookie = cookies[i];
      // let eqPos = cookie.indexOf("=");
      // let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = "token-booking=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`";
  };

  const handleSuccessfullLogin = async (response) => {
    await deleteAllCookies();
    document.cookie = "token-booking=" + response.access_token + "; path=/";

    passLogin(true);
  };

  const handleLogin = (event) => {
    if (event !== undefined && event.key !== "Enter") return; // karvalakkiversio, päästää kirjautumis pyynnön läpi kirjaudu-napin kautta tai entterillä

    setShowError(false);
    ApiCall("POST", "login", { email: userEmail, password: password })
      .then((response) => handleSuccessfullLogin(response))
      .catch((error) => {
        setShowError(true);
        passLogin(false);
        document.cookie =
          "token-booking=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      });
  };

  const switchView = () => {
    setShowError(false);
    setUserEmail("");
    setPassword("");
    setShowValidation(false);
    setShowRegister(!showRegister);
    setShowRecovery(false);
  };

  const handleRegister = (event) => {
    if (event !== undefined && event.key !== "Enter") return;

    ApiCall("POST", "user", {
      email: userEmail,
    })
      .then(() => {
        setShowValidation(true);
      })
      .catch(() => {
        /* setUserEmail(""); */
        setShowRecovery(true);
      });
  };

  const handleAuth = (event) => {
    if (event !== undefined && event.key !== "Enter") return;

    ApiCall("POST", "code_login", {
      email: userEmail,
      code: authcode,
    })
      .then((response) => {
        handleSuccessfullLogin(response);
      })
      .catch(() => setShowError(true));
  };

  /*  const handleClear = () => {
    setUserEmail("");
    setPassword("");
    deleteAllCookies();
  }; */

  const showRecoveryForm = () => {
    setShowRegister(true);
    setShowRecovery(true);
  };

  const handleRecovery = (event) => {
    if (event !== undefined && event.key !== "Enter") return;

    ApiCall("POST", "user/reset_password", {
      email: userEmail,
    })
      .then(() => {
        setShowRecovery(false);
        setShowValidation(true);
      })
      .catch(() => {
        setUserEmail("");
      });
  };

  /* useEffect(() => {
    // console.log(document)
    if (document.cookie !== "")
      ApiCall("GET", "user", null) // Jos muistista löytyy token-booking, niin testataan onko se vielä voimassa. Jos on, niin käyttäjä ohjataan päänäkymään. Jos ei, niin vanha keksi poistetaan ja käyttäjä ohjataan kirjautumisnäkymään.
        .then(() => {
          passLogin(true);
        })
        .catch(() => {
          passLogin(false);
          deleteAllCookies();
        });
  }, []); */

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Stack
        spacing={0.5}
        alignContent="center"
        alignItems="flex-start"
        sx={{
          maxWidth: "275px",
          width: "100%",
          height: "25%",
        }}
      >
        {!showRegister ? (
          <>
            <Typography
              sx={{ fontSize: "20px", fontWeight: "700", py: "5px" }}
              color="black"
            >
              Kirjaudu sisään
            </Typography>

            <TextField
              placeholder="Sähköpostiosoite..."
              type={"email"}
              onChange={(text) => setUserEmail(text.target.value)}
              value={userEmail}
              fullWidth
              inputProps={{
                style: { textAlign: "left", disableUnderline: true },
              }}
            />

            <TextField
              placeholder="Salasana..."
              onChange={(text) => setPassword(text.target.value)}
              value={password}
              type="password"
              onKeyPress={!showRegister ? handleLogin : undefined}
              fullWidth
              inputProps={{
                style: { textAlign: "left", disableUnderline: true },
              }}
            />
            <div style={{ flex: 1 }} />

            <Button
              type="submit"
              variant="contained"
              disabled={validateEmail(userEmail) || password === ""}
              onClick={() => handleLogin()}
              sx={{ width: "100%", height: "44px", fontWeight: "700" }}
            >
              Kirjaudu sisään
            </Button>

            {showError && (
              <Alert severity="error">
                Käyttäjätunnus tai salasana ei täsmää!
              </Alert>
            )}
          </>
        ) : (
          <>
            {!showValidation ? (
              <>
                <Typography
                  sx={{ fontSize: "20px", fontWeight: "700", py: "5px" }}
                  color="black"
                >
                  {!showRecovery ? "Luo tunnus" : "Palauta tunnus"}
                </Typography>

                <TextField
                  placeholder="Sähköpostiosoite"
                  value={userEmail}
                  fullWidth
                  type={"email"}
                  style={{ marginTop: 5 }}
                  onKeyPress={
                    showRecovery
                      ? handleRecovery
                      : showRegister
                      ? handleRegister
                      : undefined
                  }
                  InputProps={{
                    inputProps: {
                      style: { textAlign: "left", justifyContent: "center" },
                    },
                  }}
                  onChange={(text) => setUserEmail(text.target.value)}
                />

                <Button
                  variant="contained"
                  type="submit"
                  onClick={() =>
                    showRecovery ? handleRecovery() : handleRegister()
                  }
                  disabled={password === "" && validateEmail(userEmail)}
                  sx={{ width: "100%", height: "44px", fontWeight: "700" }}
                >
                  {showRecovery ? "Lähetä" : "Luo tunnus"}
                </Button>
                {showRecovery && (
                  <Alert severity="info">
                    Lähetämme kertakäyttöisen kirjautumiskoodin sähköpostiisi.
                    Jos haluat hallinnoida tunnustasi tai varauksiasi, lataa
                    ToloGo-sovellus.
                  </Alert>
                )}
              </>
            ) : (
              <>
                <Typography
                  sx={{ fontSize: "20px", fontWeight: "900", py: "5px" }}
                  color="black"
                >
                  Vahvista tunnus
                </Typography>

                <Typography
                  color="white"
                  sx={{ fontSize: "14px", textAlign: "flex-start" }}
                >
                  Syötä vahvistuskoodi sähköpostistasi
                </Typography>

                <TextField
                  placeholder="Vahvistuskoodi"
                  type="tel"
                  fullWidth
                  value={authcode}
                  style={{ marginTop: 5 }}
                  onKeyPress={
                    !showValidation
                      ? undefined
                      : authcode.length === 4
                      ? handleAuth
                      : undefined
                  }
                  onChange={(text) => {
                    const regex = /^[0-9\b]+$/;
                    const userInput = text.target.value;

                    if (
                      userInput.length <= 4 &&
                      (userInput === "" || regex.test(userInput))
                    )
                      setAuthcode(userInput);
                  }}
                  InputProps={{
                    inputProps: {
                      style: {
                        textAlign: "left",
                        justifyContent: "center",
                      },
                    },
                  }}
                />

                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => handleAuth()}
                  disabled={authcode.length !== 4}
                  inputProps={{
                    style: { textAlign: "center" },
                    disableUnderline: true,
                  }}
                  sx={{ width: "100%", height: "44px", fontWeight: "700" }}
                >
                  Lähetä
                </Button>

                {showError && (
                  <Alert severity="error">
                    Vahvistuskoodi ei kelpaa. Varmista antamasi koodi tai pyydä
                    uusi vahvistuskoodi.
                  </Alert>
                )}
              </>
            )}
          </>
        )}
        <Divider sx={{ width: "100%" }} />
        <Stack direction="row" justifyContent="space-between" width={"100%"}>
          <Button
            variant="bookingButton"
            onClick={() => switchView()}
            sx={{ width: "48%", fontWeight: "700" }}
          >
            {showRegister ? "Takaisin kirjautumiseen" : "Rekisteröidy"}
          </Button>
          <Button
            variant="bookingButton"
            onClick={() => showRecoveryForm()}
            sx={{ width: "48%", fontWeight: "700" }}
          >
            Unohtuiko salasana?
          </Button>
        </Stack>
      </Stack>

      <Stack
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        textAlign="center"
        spacing={0}
        sx={{
          backgroundColor: "transparent",
          paddingTop: "15px",
        }}
      >
        <img
          src={TextLogo}
          alt=""
          style={{ maxHeight: "75px", maxWidth: "100px" }}
        />
        <Typography
          color={ColorScheme.tolotechLightBlue}
          sx={{ fontSize: "12px" }}
        >
          {"©" + new Date().getFullYear()}
        </Typography>
      </Stack>
    </Box>
  );
};

export default Login;
