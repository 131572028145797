import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { isAndroid, isIOS, MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Checkmark } from "../../Assets/icon-checkmark.svg";
import { ColorScheme } from "../../Utils/ColorScheme";

export const PaymentSuccess = ({ path }) => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(`/`);
  };

  return (
    <Stack
      spacing={6}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      sx={{ padding: "10%", filter: "opacity(100%)" }}
    >
      <Box sx={{height: "100px", width: "100px", border: "5px solid " + ColorScheme.green, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "100px"}}>
      <Checkmark width="60px" height="60px" strokeWidth={"5px"} stroke={ColorScheme.green} />
      </Box>
      <Typography sx={{fontWeight: "700"}}>
        Maksu onnistui, saat pian sähköpostiisi vahvistusviestin!
      </Typography>
      <Typography sx={{fontSize: "14px"}}>
        {/* Jos tilaukseesi sisältyy tilavaraus ja siihen on liitetty kulkuoikeus oveen, löydät sähköpostiviestistä
        mahdollisen PIN-koodin. */}
        {/* Lähetämme sähköpostiisi PIN-koodin, mikäli tilaukseesi sisältyy
        tilavaraus kulkuoikeuden kanssa. */}
       Jos tilauksesi sisältää kulkuoikeuksia, löydät pin-koodin vahvistusviestistä. Varauksesi, kulkuoikeutesi, ja paljon muuta löydät ToloGo-sovelluksesta!
      </Typography>

      <MobileView>
        {isAndroid && (
          <Typography>
            Lataa sovellus{" "}
            <a
              href="https://play.google.com/store/apps/details?id=fi.tolotech.TOLOGO"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Play-kaupasta.
            </a>
          </Typography>
        )}
        {isIOS && (
          <Typography sx={{fontWeight: "700"}}>
            Lataa sovellus{" "}
            <a
              href="https://apps.apple.com/fi/app/tologo/id1540759545"
              target="_blank"
              rel="noopener noreferrer"
            >
              App Storesta.
            </a>
          </Typography>
        )}
      </MobileView>
      <Button
        sx={{ padding: "10px", borderRadius: "4px", boxShadow: "none", filter: "opacity(100%)", fontSize: "14px" }}
        variant="contained"
        onClick={() => handleRedirect()}
      >
        Palaa takaisin alkuun
      </Button>
    </Stack>
  );
};
