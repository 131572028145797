import React, { useState, useEffect } from "react";
import { ColorScheme } from "../../Utils/ColorScheme";
import SVG from "react-inlinesvg";
import { Typography } from "@mui/material";
import { ReactComponent as ToggleArrow } from "../../Assets/NavigationArrowRight.svg";

export const CardLabels = ({ start, date, end, order, isCollapsed }) => {
  const isOrder = Boolean(order.order_id);
  /* const [iconSvg, setIconSvg] = useState(""); */
  const [locationLogo, setLocationLogo] = useState("");
  const [serviceLogo, setServiceLogo] = useState("");

  /*  const getStatusColor = () => {
    if (order.order_succesful && order.refund_status !== 1) {
      return ColorScheme.green;
    }

    if (order.refund_status === 1) {
      return ColorScheme.purple;
    }

    if (order.refund_status !== 1 && !order.order_succesful) {
      return ColorScheme.softRed;
    }
  }; */

  function modifySvg(svgString, stroke, strokeWidth) {
    const styleString = `stroke="${stroke}" stroke-width="${strokeWidth}"`;

    let newString = svgString.replace("<svg", `<svg ${styleString}`);
    return newString;
  }

  useEffect(() => {
    if (order.short_reservable && order.short_reservable.service_class.image) {
      let svg = modifySvg(
        order.short_reservable.service_class.image,
        "black",
        "0.9"
      );

      setServiceLogo(svg);
    }
  }, [order.short_reservable?.service_class.image]);

  useEffect(() => {
    if (
      order.location &&
      order.location.logo_image &&
      order.location.logo_image.image
    ) {
      setLocationLogo(order.location.logo_image.image);
    }
  }, [order.location?.logo_image?.image]);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "row",
        paddingTop: "6px",
        paddingBottom: "6px",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {!isOrder ? (
          <div
            style={{
              display: "flex",
              paddingRight: "10px",
              width: "50px",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <SVG src={serviceLogo} width={30} height={30} title="class" />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              paddingRight: "10px",
              width: "50px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={locationLogo}
              alt=""
              title="location"
              style={{
                maxWidth: "38px",
                maxHeight: "38px",
                objectFit: "cover",
              }}
            />
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div
            style={{
              overflowWrap: "anywhere",
              fontWeight: "700",
              // marginLeft: "10px",
            }}
          >
            {isOrder ? (
              <div>
                <Typography fontWeight={700}>{order.location.name}</Typography>
                <Typography
                  fontWeight={400}
                  color={"#A6A6A6"}
                  fontSize={"13px"}
                  lineHeight={0.9}
                >
                  #{order.order_id}
                </Typography>
              </div>
            ) : (
              <Typography fontWeight={700}>
                {order.short_reservable?.name}
              </Typography>
            )}
          </div>

          <div
            style={{ fontSize: "10px", fontWeight: "700", color: "#A6A6A6" }}
          >
            {!isOrder ? order.short_reservable?.location?.name : undefined}
          </div>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
        {!isOrder &&
          order.user_reservations[0].reservation_accesses[0]?.door_access
            ?.pin_code && (
            <div
              style={{
                display: "flex",
                height: "100%",

                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <div
                style={{
                  backgroundColor: ColorScheme.tolotechDarkBlue,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  minWidth: "55px",
                  height: "12px",
                  borderRadius: "2px",
                  padding: "2px",
                }}
              >
                <Typography color={"white"} fontWeight={700} fontSize={"10px"}>
                  PIN:{" "}
                  {
                    order.user_reservations[0].reservation_accesses[0]
                      ?.door_access?.pin_code
                  }
                </Typography>
              </div>
            </div>
          )}

        <div
          style={{
            display: "flex",
            textAlign: "right",
            alignSelf: "center",
            justifyContent: "right",
            fontSize: "14px",
            fontWeight: !isOrder && "600",
          }}
        >
          <div style={{ minWidth: "80px" }}>
            <Typography fontWeight={700}>
              {isOrder
                ? parseFloat(Number(order.total_vat_price).toFixed(2)).toString().replace('.', ',') + " €"
                : String(start?.getDate() || date.getDate()).padStart(2, "0") +
                  "." +
                  String(start?.getMonth() + 1 || date.getMonth() + 1).padStart(
                    2,
                    "0"
                  )}
            </Typography>

            {isOrder ? (
              <Typography
                fontSize={"12px"}
                color={"#A6A6A6"}
                fontWeight={400}
                lineHeight={0.9}
              >
                {String(start?.getDate() || date.getDate()).padStart(2, "0") +
                  "." +
                  String(start?.getMonth() + 1 || date.getMonth() + 1).padStart(
                    2,
                    "0"
                  ) +
                  "." +
                  String(start?.getFullYear() || date.getFullYear()).slice(2) +
                  " " +
                  String(date.getHours()).padStart(2, "0") +
                  ":" +
                  String(date.getMinutes()).padStart(2, "0")}
              </Typography>
            ) : (
              <Typography
                fontSize={"12px"}
                color={"#A6A6A6"}
                fontWeight={700}
                lineHeight={0.9}
              >
                {String(start?.getHours()).padStart(2, "0") +
                  ":" +
                  String(start?.getMinutes()).padStart(2, "0")}{" "}
                -{" "}
                {String(end?.getHours()).padStart(2, "0") +
                  ":" +
                  String(end?.getMinutes()).padStart(2, "0")}
              </Typography>
            )}
          </div>

          <div
            style={{
              display: !isOrder && "none",
              width: "10px",
              height: "10px",
              //border: "1px solid" + ColorScheme.veryLightGrey,
              /*  boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.25)", */
              borderRadius: "8px",
              /* backgroundColor: getStatusColor(), */
              alignSelf: "center",
              marginLeft: "15px",
              marginRight: "5px",
              transformOrigin: "center",
              transform: `rotate(${
                isCollapsed ? "90deg" : "0deg"
              }) translateY(-50%)`,
              transition: "transform 0.2s ease-in-out",
            }}
          >
            <ToggleArrow stroke="black" width="10px" height="10px" />
          </div>
        </div>
      </div>
    </div>
  );
};
