import React from "react";

import { ReactComponent as BalanceUpIcon } from "../../Assets/BalanceUpIcon.svg";
import { ReactComponent as BalanceDownIcon } from "../../Assets/BalanceDownIcon.svg";

import { ColorScheme } from "../../Utils/ColorScheme";
import { Box, Typography } from "@mui/material";
import { parseTimestamp } from "../../Utils/parseTimestamp";

export const LocationBalanceEntry = ({ data, isFirst, isLast }) => {
  const dateStamp = parseTimestamp(data.created_at);

  const checkValue = (value) => {
    switch (Math.sign(value)) {
      case 1:
        return <BalanceUpIcon stroke={ColorScheme.green} />;

      case -1:
        return <BalanceDownIcon stroke={ColorScheme.red} />;

      default:
        break;
    }
  };
 
  // console.log(data);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        border: /* isFirst ? "none" : */ "1px solid #D9D9D9",
        borderTop: !isFirst ? "none" : "1px solid #D9D9D9",
        borderRadius:
          isFirst && isLast
            ? "4px"
            : isFirst
            ? "4px 4px 0px 0px"
            : isLast
            ? "0px 0px 4px 4px"
            : "0px",
        padding: "20px",
        transition: "all 0.2s ease-in-out",
      }}
    >
      <Box
        sx={{
          display: "inherit",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "calc(100% / 3)",
            display: "flex",
            flexDirection: "row",
            gap: "20px",
          }}
        >
          {checkValue(data.amount)}

          <Typography>{dateStamp}</Typography>
        </div>

        {/*  <div style={{ width: "calc(100% / 3)", textAlign: "center" }}>
          <Typography
            fontWeight="200"
            fontSize="16px"
            color={ColorScheme.tolotechLightBlue}
          >
            #{data.order_id}
          </Typography>
        </div> */}

        <div
          style={{
            width: "calc(100% / 3)",
            textAlign: "right",
          }}
        >
          <Typography fontWeight="700">
            {parseFloat(data.amount).toFixed(2).replace(".", ",")} €
          </Typography>
        </div>
      </Box>
    </div>
  );
};
