import React, { useRef, useEffect } from "react";
import { Typography } from "@mui/material";

export const CardText = ({
  // receives content and settings to calculate proper content height before hiding rest of overflowing content
  text,
  maxLines,
  lineHeight,
  fontSize,
  fontWeight,
  color
}) => {
  const containerRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const content = contentRef.current;

    const containerHeight = container.clientHeight;
    const maxContentHeight = maxLines * lineHeight;

    if (containerHeight > maxContentHeight) {
      const ellipsisLines = Math.floor(maxContentHeight / lineHeight);
      content.style.webkitLineClamp = ellipsisLines;
    }
  }, [text, maxLines, lineHeight]);

  return (
    <div ref={containerRef}>
      <Typography
        ref={contentRef}
        fontSize={fontSize}
        fontWeight={fontWeight}
        sx={{
          color: color,
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          WebkitLineClamp: maxLines,
          maxHeight: `${maxLines * lineHeight}px`,
          lineHeight: `${lineHeight}px`,
        }}
      >
        {text}
      </Typography>
    </div>
  );
};
