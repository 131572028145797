import { Button, Checkbox, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { ColorScheme } from "../../Utils/ColorScheme";
import "../../Styles/Transitions.css";
import { BASE_URL } from "../../env";
import { ApiContext } from "../../Utils/ApiContext";
import { UserContext } from "../../Utils/UserContext";

export const BottomSheet = ({
  selectedReservations,
  selectedProducts,
  phase,
  selectedField,
  eraseContent,
  nextPhase,
  checkoutVisible,
}) => {
  const days = [
    "Sunnuntai",
    "Maanantai",
    "Tiistai",
    "Keskiviikko",
    "Torstai",
    "Perjantai",
    "Lauantai",
  ];

  function calculateDuration() {
    if (selectedReservations.length === 0) return;

    let date = new Date(selectedReservations[0].from_time);

    let string =
      days[date.getDay()] + " " + date.getDate() + "." + (date.getMonth() + 1);

    if (selectedReservations.length === 1) {
      return (
        string +
        " " +
        `${moment(selectedReservations[0].from_time).format(
          "HH:mm"
        )} - ${moment(selectedReservations[0].to_time).format("HH:mm")}`
      );
    } else {
      const sortArray = selectedReservations.sort((a, b) =>
        a.from_time.localeCompare(b.from_time)
      );

      const check = sortArray.some(
        (timeslot, index, array) =>
          index > 0 && timeslot.from_time !== array[index - 1].to_time
      );

      return check
        ? "Huom. kaikki vuorot eivät ole peräkkäisiä aikoja!"
        : string +
            " " +
            `${moment(sortArray[0].from_time).format("HH:mm")} - ${moment(
              sortArray[sortArray.length - 1].to_time
            ).format("HH:mm")}`;
    }
  }

  function reservationPrice() {
    let price = 0;
    selectedReservations.forEach((reservation) => {
      price += reservation.vat_price;
    });
    return Math.round(price * 100) / 100;
  }

  function productPrice() {
    let price = 0;
    selectedProducts.forEach((product) => {
      price += product.vat_price;
    });
    return Math.round(price * 100) / 100;
  }

  useEffect(() => {
    if (
      (selectedReservations.length > 0 || selectedProducts.length > 0) &&
      phase !== 2
    )
      checkoutVisible(true);
    else checkoutVisible(false);
  }, [selectedReservations, selectedProducts, phase]);

  return (
    <CSSTransition
      unmountOnExit
      timeout={300}
      in={
        (selectedReservations.length > 0 || selectedProducts.length > 0) &&
        phase != 2
      }
      classNames={"slide-from-bottom"}
    >
      <Box
        sx={{
          width: "100%",
          position: "fixed",
          bottom: -1,
          left: 0,
          borderRadius: "10px 10px 0px 0px",
          backgroundColor: ColorScheme.tolotechDarkBlue,
          color: ColorScheme.tolotechWhite,
          zIndex: 9,
          overflow: "hidden",
          boxShadow: "0px 0px 30px 10px rgba(0,0,0,0.1)",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            padding: "20px",
            display: "flex",
            flexDirection: "row",
            /*  maxWidth: "1200px", */
          }}
        >
          <Box
            sx={{
              width: "100%",

              height: "100%",
              minHeight: "100px",
              display: "flex",
              justifyContent: "space-between",
              /* py: "15px",
                            px: "5px", */
            }}
          >
            <Stack
              direction="column"
              display={"flex"}
              width="100%"
              minWidth="220px"
              justifyContent="space-between"
              alignItems="center"
            >
              {phase != 2 && (
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: "1150px", // 1200px - 2*25px padding for limited desktop width
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      flexDirection: "column",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography
                      color={"white"}
                      sx={{ margin: 0, fontSize: "16px" }}
                      fontWeight="700"
                    >
                      {selectedField?.name}
                    </Typography>
                  </Box>

                  {selectedReservations.length != 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "flex-start",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        color={"white"}
                        fontWeight="500"
                        fontSize="12px"
                      >
                        {calculateDuration()}
                      </Typography>
                      <Typography
                        color={"white"}
                        fontWeight="500"
                        fontSize="12px"
                      >
                        {reservationPrice()} €
                      </Typography>
                    </Box>
                  )}
                  {selectedProducts.length != 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "flex-start",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        color={"white"}
                        fontWeight="500"
                        fontSize="12px"
                      >
                        {"Tuotteet"}
                      </Typography>
                      <Typography
                        color={"white"}
                        fontWeight="500"
                        fontSize="12px"
                      >
                        {productPrice()} €
                      </Typography>
                    </Box>
                  )}
                  <Box
                    sx={{
                      width: "100%",
                      height: 2,
                      backgroundColor: "rgba(255,255,255,0.2)",
                      my: "10px",
                    }}
                  ></Box>
                  {
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "flex-start",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        style={{ fontSize: "16px", fontWeight: "700" }}
                      >
                        {"Yhteensä"}
                      </Typography>
                      <Typography
                        color={"white"}
                        fontWeight="700"
                        fontSize="16px"
                      >
                        {(productPrice() + reservationPrice()).toFixed(2)} €
                      </Typography>
                    </Box>
                  }
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  maxWidth: "1150px", // 1200px - 2*25px padding for limited desktop width
                  height: "100%",
                  alignSelf: "center",
                  justifyContent: "space-between",
                  flex: 1,
                  marginTop: "30px",
                }}
              >
                <Box
                  onClick={() => eraseContent()}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignSelf: "center",
                    alignContent: "center",
                    justifyContent: "center",
                    /* width: "140px", */
                    width: "100%",
                    height: "25px",
                    /* padding: "10px", */
                    py: "10px",
                    borderRadius: "4px",
                    fontSize: "18px",
                    border: "2px solid " + ColorScheme.tolotechWhite,
                    color: ColorScheme.tolotechWhite,
                    transition: ".3s all",
                    "&:hover": {
                      cursor: "pointer",
                      transform: {
                        scale: 1.1,
                      },
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      textAlign: "center",
                      fontWeight: "600",
                    }}
                  >
                    Keskeytä
                    {/*  {!showProducts ? "Jatka maksamaan" : "Jatka"} */}
                  </Typography>
                </Box>
                <div style={{ width: "20px" }}></div>
                <Box
                  onClick={() => nextPhase()}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignSelf: "center",
                    alignContent: "center",
                    justifyContent: "center",
                    /* width: "140px", */
                    width: "100%",
                    height: "25px",
                    /* padding: "10px", */
                    py: "10px",
                    borderRadius: "4px",
                    fontSize: "18px",
                    backgroundColor: ColorScheme.tolotechWhite,
                    border: "2px solid " + ColorScheme.tolotechWhite,
                    color: ColorScheme.tolotechDarkBlue,
                    transition: ".3s all",
                    "&:hover": {
                      cursor: "pointer",
                      transform: {
                        scale: 1.1,
                      },
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      textAlign: "center",
                      fontWeight: "600",
                    }}
                  >
                    Jatka
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
    </CSSTransition>
  );
};
