import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// import { BlankPage } from "../Components/BlankPage";
import { Frontpage } from "../Components/Frontpage/Frontpage";
import { MainContent } from "../Components/Main/MainContent";
import { PaymentCancel } from "../Components/Payment/PaymentCancel";
import { PaymentSuccess } from "../Components/Payment/PaymentSuccess";
import { UserManagement } from "../Components/Management/UserManagement";
import { UserNotifications } from "../Components/Management/UserNotifications";

export const Router = ({ path, data, passLoadingState }) => {
  return (
    <Routes>
      <Route path="/location/:id" element={<MainContent />} />

      <Route
        exact
        path="/:id"
        element={<Navigate replace to={`/location/${path}`} />} // url reroute /:id -> /location/:id
      />

      <Route exact path="/user" element={<UserManagement />} />
      <Route exact path="/notifications" element={<UserNotifications />} />
      <Route exact path="/success" element={<PaymentSuccess path={path} />} />
      <Route exact path="/cancel" element={<PaymentCancel path={path} />} />
      <Route path="/*" element={<Frontpage />} />
      <Route exact path="/" element={<Frontpage />} />
    </Routes>
  );
};
