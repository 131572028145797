export const parseTimestamp = (date, option) => {
  // Parse timestamp to custom format
  // option 1 = date only
  // option 2 = date and time
  // default = date and time
  // add more options if needed

  const dateToParse = new Date(date);

  const customDate =
    dateToParse.getDate().toString().padStart(2, "0") +
    "." +
    (dateToParse.getMonth() + 1).toString().padStart(2, "0") +
    "." +
    dateToParse.getFullYear();

  const customTime =
    dateToParse.getHours().toString().padStart(2, "0") +
    ":" +
    dateToParse.getMinutes().toString().padStart(2, "0") +
    ":" +
    dateToParse.getSeconds().toString().padStart(2, "0");

  const timestamp = () => {
    switch (option) {
      case 1:
        return customDate;

      case 2:
        return customDate + " " + customTime;

      default:
        return customDate + " " + customTime;
    }
  };

  return timestamp();
};
