import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { ColorScheme } from "../../Utils/ColorScheme";

import { ApiContext } from "../../Utils/ApiContext";
import FieldSlotCard from "./FieldSlotCard";
import { CSSTransition } from "react-transition-group";

import "../../Styles/Transitions.css";

// import { ApiContext } from "../../Utils/dev/ApiContext_test";

export const FieldSlots = ({
  field,
  smallestGap,
  startingTime,
  location,
  selectedDate,
  slotHeight,
  reservationClicked,
  selectedReservations,
  minWidth,
}) => {
  const { ApiCall } = useContext(ApiContext);

  const [reservations, setReservations] = useState([]);
  const [abortController, setAbortController] = useState(null);
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    if (reservations.length > 0) setIsRendered(true);
  }, [reservations]);

  useEffect(() => {
    setIsRendered(false);
    setReservations([]);
  }, [selectedDate]);

  useEffect(() => {
    let currentController = new AbortController();
    const { signal } = currentController;

    if (abortController) {
      abortController.abort();
    }
    setIsRendered(false);
    setReservations([]);

    setAbortController(currentController);

    const fetchData = async () => {
      try {
        const response = await ApiCall(
          "GET",
          `location/get_reservable_times/${location.location_id}?reservable=${
            field.id
          }&date=${new Date(selectedDate).toISOString()}`,
          null,
          signal
        )
          .then((response) => {
            return response;
          })
          .catch(() => {
            // console.log("rikki", error);
            return [];
          });

        setReservations(response);
      } catch (error) {
        if (error.name === "CanceledError") {
          console.log("Vanha pyyntö perutettiin");
        } else {
          console.error("Error:", error?.message);
        }
      } finally {
        if (currentController) {
          currentController = null;
        }
      }
    };

    fetchData();

    return () => {
      if (currentController) {
        currentController.abort();
        currentController = null;
      }
    };
  }, [field, selectedDate]);

  function canBeSelected(reservation) {
    if (selectedReservations.length === 0) {
      return true;
    }

    const isDifferentReservable =
      selectedReservations[0].reservable_id !== reservation.reservable_id;

    // Check if the new reservation is adjacent to any existing reservation
    const isAdjacent = selectedReservations.some(
      (selected) =>
        selected.to_time === reservation.from_time ||
        selected.from_time === reservation.to_time
    );

    // Check if the new reservation is the lowest or highest time among the selected reservations
    const isLowestTime = selectedReservations.every(
      (selected) => reservation.from_time <= selected.from_time
    );
    const isHighestTime = selectedReservations.every(
      (selected) => reservation.to_time >= selected.to_time
    );

    if (
      selectedReservations.length === 1 &&
      selectedReservations[0].reservation_id === reservation.reservation_id
    )
      return true;

    return (
      !isDifferentReservable && isAdjacent && (isLowestTime || isHighestTime)
    );
  }

  function calculatePaddingFromTop(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();

    let minutesFromMidnight = hours * 60 + minutes;
    return (
      ((minutesFromMidnight - startingTime) / smallestGap) * slotHeight + 104
    );
  }

  function getLengthInMinutes(slot) {
    const from = moment(slot.from_time);
    const to = moment(slot.to_time);
    return to.diff(from, "minutes");
  }

  function colorSwitch(slot) {
    return selectedReservations.some(
      (reservation) => reservation.reservation_id === slot.reservation_id
    )
      ? ColorScheme.select_green
      : !canBeSelected(slot)
      ? ColorScheme.lightGrey
      : ColorScheme.tolotechBlue;
  }

  return (
    <Box
      spacing={2}
      sx={{
        alignItems: "center",
        boxSizing: "border-box",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        marginRight: "2px",
        zIndex: 1,
        flexGrow: 1,
        flexBasis: 0,
        minWidth: minWidth + "px", // default width instead of random width based on field name length
      }}
    >
      <Box
        style={{
          height: "100px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography style={{ overflow: "hidden", userSelect: "none" }}>
          {field.name}
        </Typography>
      </Box>

      <CSSTransition
        classNames={"fade-map"}
        timeout={{ enter: 500, exit: 500 }}
        unmountOnExit
        in={isRendered}
        appear
        /* onEntered={() => setIsRendered(true)} */
      >
        <Box>
          {reservations.map((slot, i) => {
            return (
              <FieldSlotCard
                key={slot.reservation_id}
                {...{
                  slot,
                  i,
                  canBeSelected,
                  reservationClicked,
                  field,
                  calculatePaddingFromTop,
                  getLengthInMinutes,
                  smallestGap,
                  slotHeight,
                  colorSwitch,
                  selectedReservations,
                }}
              />
            );
          })}
        </Box>
      </CSSTransition>
    </Box>
  );
};
