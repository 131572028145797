import { Box, Collapse, Typography } from "@mui/material";
import React, { useState } from "react";
import { LocationBalanceEntry } from "./LocationBalanceEntry";
import { LocationBalanceSmall } from "./LocationBalanceSmall";
import { parseTimestamp } from "../../Utils/parseTimestamp";
import { isDesktop } from "react-device-detect";

export const LocationBalance = ({ data, isFirst, amount }) => {
  const [open, setOpen] = useState(false);
  const showMore = amount > 1;
  const transactions = data.transactions;

  const expireDate = parseTimestamp(data.expires_at, 1);
  const lastUsedDate = parseTimestamp(data.updated_at, 2);

  return (
    <Box
      sx={{
        borderTop: isFirst ? "none" : "1px solid #D9D9D9",
        /* borderRadius: "4px", */
        overflow: "hidden",
        /*  minHeight: "1000px", */
        padding: isDesktop ? "20px 20px" : "10px 20px",
        cursor: "pointer", 
        backgroundColor: showMore ? "#FAFAFA" : "transparent",
      }}
      onClick={() => (showMore ? setOpen(!open) : undefined)}
    >
      <Collapse in={showMore ? !open : false}>
        <LocationBalanceSmall data={data} />
      </Collapse>

      <Collapse in={showMore ? open : true}>
        <Box
          sx={{
            borderRadius: "4px",
            padding: "30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              textAlign: "center",
              gap: "20px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Typography
                fontSize={isDesktop ? 28 : 24}
                fontWeight={500}
                lineHeight={1}
              >
                {data.location.name}
              </Typography>
              <Typography
                fontSize={isDesktop ? 22 : 20}
                fontWeight={300}
                lineHeight={1}
              >
                Oma saldo
              </Typography>

              <Typography
                fontSize={isDesktop ? 70 : 42}
                fontWeight={700}
                lineHeight={1.5}
              >
                {parseFloat(data.amount).toFixed(2).replace(".",",")} €
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-around",
                gap: "25px",
              }}
            >
              <Box>
                <Typography
                  fontSize={isDesktop ? 18 : 16}
                  fontWeight={300}
                  lineHeight={1}
                >
                  Viimeisin tapahtuma
                </Typography>
                <Typography
                  fontSize={isDesktop ? 22 : 16}
                  fontWeight={500}
                  lineHeight={2}
                >
                  {lastUsedDate}
                </Typography>
              </Box>

              <Box>
                <Typography
                  fontSize={isDesktop ? 18 : 16}
                  fontWeight={300}
                  lineHeight={1}
                >
                  Viimeinen käyttöpäivämäärä
                </Typography>
                <Typography
                  fontSize={isDesktop ? 22 : 16}
                  fontWeight={500}
                  lineHeight={2}
                >
                  {expireDate}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            /* border: "1px solid #D9D9D9", */
            borderRadius: "4px",
            overflow: "hidden",
            /* maxWidth: "1000px", */
            /*  margin: "auto", */
            padding: isDesktop ? "0px 40px" :  "0px 10px",
            marginBottom: "40px",
          }}
        >
          {transactions.map((reservation, i) => {
            return (
              <LocationBalanceEntry
                key={i}
                data={reservation}
                isFirst={i === 0}
                isLast={transactions.length - 1 === i}
              />
            );
          })}
        </Box>
      </Collapse>
    </Box>
  );
};
