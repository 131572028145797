import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import { ReactComponent as LoginIcon } from "../../Assets/login-logout-arrow.svg";
import { ColorScheme } from "../../Utils/ColorScheme";

export const UserLoginButton = ({ handlePopover, user }) => {
  return (
    <Box
      onClick={(event) => handlePopover(event)}
      sx={{
        height: "35px",
        width: "35px",
        maxWidth: "35px",
        fontSize: "20px",
        fontWeight: "900",
        margin: "0px",
        backgroundColor: "#7C97B0",
        borderRadius: "40px",
        color: ColorScheme.tolotechWhite,
        boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.1)",
        border: "1px solid transparent", // fix to flickering
        "&:hover": {
          border: `1px inset rgba(100,100,100,0.2)`,
          boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.2)",
        },
        filter: "opacity(100%)",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flex: 1,
        zIndex: 2,
      }}
    >
      {user !== null ? (
        <Typography fontWeight="700">
          {user.email.charAt(0).toUpperCase()}
        </Typography>
      ) : (
        <LoginIcon fill="none" stroke="white" height="18px" />
      )}
    </Box>
  );
};
