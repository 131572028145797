import { CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { CSSTransition } from "react-transition-group";
//  import { UserContext } from "../../Utils/UserContext";
import { ApiContext } from "../../Utils/ApiContext";
import { UserContext } from "../../Utils/UserContext";
import { LocationCard } from "./LocationCard";

function DelayedRenderItem({ item }) {
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <CSSTransition
      key={item.location_id}
      classNames="fade-in"
      timeout={700}
      unmountOnExit
      in={isRendered}
    >
      <LocationCard data={item} key={item.location_id} />
    </CSSTransition>
  );
}

export const Frontpage = () => {
  const { ApiCall } = useContext(ApiContext);
  const { isLogged } = useContext(UserContext);

  const debug = false;

  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showItems, setShowItems] = useState(false);

  const fetchLocationData = () => {
    setIsLoading(true);

    ApiCall("GET", "locations?type=reservable", null)
      .then((res) => {

       setLocations(res);
        // console.log(res);
      })
      .catch((error) => {
        if (debug) console.log(error);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchLocationData();
  }, []);

  useEffect(() => {
    setShowItems(true);
  }, [isLoading]);

  useEffect(() => {
    fetchLocationData();
  }, [isLogged]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            textAlign: "center",
            marginTop: "10%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexBasis: "100%",
              // marginLeft: isMobile ? "30px" : "70px",
              justifyContent: isMobile ? "space-around" : undefined,
              paddingBottom: "20px",
            }}
          >
            <div style={{ width: isMobile ? "40%" : undefined }} />
          </div>

          <div
            style={{
              width: "100%",
              margin: "auto",
            }}
          >
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
                rowGap: "15px",
              }}
            >
              {locations.map((entry, i) => (
                <DelayedRenderItem
                  key={i}
                  item={entry}
                  index={i}
                  show={showItems}
                  style={{ margin: "15px" }}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
