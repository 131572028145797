import React from "react";
import { ReactComponent as ArrowLeft } from "../../Assets/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../Assets/ArrowRight.svg";

export const ArrowButton = ({ direction }) => {
  const height = "48px";
  const width = "48px";

  const arrowSwitch = () => {
    switch (direction) {
      case "left":
        return <ArrowLeft height={height} width={width} />;

      case "right":
        return <ArrowRight height={height} width={width} />;

      default:
        break;
    }
  };

  return (
    <div
      style={{
        cursor: "pointer",
        // border: "1px solid black",
      }}
    >
      {arrowSwitch()}
    </div>
  );
};
