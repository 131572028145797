import React, { useContext, useEffect, useState } from "react";
import ClassSelector from "../Calendar/ClassSelector";
import DaySelector from "../Calendar/DaySelector";
import { Box, CircularProgress } from "@mui/material";
import { ProductSelection } from "../Products/ProductContent";
import { Timetable } from "../Calendar/Timetable";
import "../../Styles/index.css";
import { BottomSheet } from "./BottomSheet";
import { Checkout } from "../Checkout/Checkout";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiContext } from "../../Utils/ApiContext";
import { EmptyPage } from "./EmptyPage";

export const MainContent = () => {
  const { ApiCall } = useContext(ApiContext);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());

  // Phase 0 = reservation_calendar, phase 1 = product_selection, phase 2 = confirm_selected
  const [phase, setPhase] = useState(0);

  const [selectedReservations, setSelectedReservations] = useState([]);
  const [selectedField, setSelectedField] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [extraSpace, setExtraSpace] = useState(false);

  const [reveal, setReveal] = useState(false);
  const [showHelperText, setShowHelperText] = useState(false);

  const [data, setData] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  const slicedPath =
    location.pathname === "/success" || location.pathname === "/cancel"
      ? location.search.slice(5) // exclude first 5 characters ie. ?lid=
      : location.pathname.slice(location.pathname.lastIndexOf("/") + 1);

  const fetchLocationData = () => {
    ApiCall("GET", `locations?id=${slicedPath}`, null)
      .then((res) => {
        setData(res[0]);

        if (res.length === 0) {
          // show helper text component if location doesn't exist or is hidden
          setShowHelperText(true);
        }
      })
      .catch((error) => {
        // console.log(error);
        if (
          /* error.response.status === 404 || */ error.response.status === 500
        )
          navigate("/");
      });
  };

  const handlePhaseSkip = (value) => {
    // used to skip phases when location has no reservables or products
    setPhase(value);
  };

  const handleReturnBack = () => {
    // console.log("tää", phase, selectedDate, selectedClass, selectedClass.type,data);
    if (phase === 2 && data.has_products) {
      // if location has products, go back to product selection phase
      setPhase(1);
    } else eraseContent();
  };

  const handleExtraSpace = (value) => {
    setExtraSpace(value);
  };

  function reservationClicked(reservation, field) {
    //  console.log(reservation);

    const index = selectedReservations.findIndex(
      (r) => r.reservation_id === reservation.reservation_id
    );
    if (index !== -1) {
      // Reservation already exists, remove it
      const temp = [...selectedReservations];
      temp.splice(index, 1);
      setSelectedReservations(temp);
    } else {
      // Reservation doesn't exist, add it
      setSelectedReservations([...selectedReservations, reservation]);
      setSelectedField(field);
    }
  }

  function addProduct(product) {
    setSelectedProducts([...selectedProducts, product]);
  }

  function removeProduct(product) {
    const index = selectedProducts.findIndex((p) => p.id === product.id);
    if (index !== -1) {
      const temp = [...selectedProducts];
      temp.splice(index, 1);
      setSelectedProducts(temp);
    }
  }

  function eraseContent() {
    setSelectedReservations([]);
    setSelectedField(null);
    setSelectedProducts([]);

    if (data !== null && data.has_products && !data.has_reservations)
      setPhase(1);
    // if location has products but no reservables, stay at product phase
    else setPhase(0); // return to phase 0 after erasing content

    /* if (selectedClass.type == 0) {
      if (data !== null && data.has_products && !data.has_reservations)
        setPhase(1);
      else setPhase(0);
    } else {
      setPhase(1);
    } */
  }

  function nextPhase() {
    if (phase == 0) {
      setPhase(1);
    } else if (phase == 1) {
      setPhase(2);
    }
  }

  useEffect(() => {
    if (selectedReservations.length == 0) {
      setSelectedField(null);
    }
  }, [selectedReservations]);

  useEffect(() => {
    if (selectedClass != null) {
      if (typeof selectedClass !== "object" && selectedClass !== null) {
        setPhase(0);
        setSelectedProducts([]);
        setSelectedReservations([]);
        return;
      }

      if (selectedClass?.type == 0) {
        // if type isn't 0 (short reservable), skip to product phase
        setPhase(0);
      } else {
        setPhase(1);
      }
    }

    /*  if (data !== null && data.has_products && !data.has_reservations) {
      // if location has products but no reservations, skip to product phase
      setPhase(1);
    } */

    setSelectedProducts([]);
    setSelectedReservations([]);
  }, [selectedClass, selectedDate]);

  useEffect(() => {
    if (data) setReveal(true); // if data exists, reveal the content
  }, [data]);

  useEffect(() => {
    if (
      isNaN(slicedPath) &&
      location.pathname !== "/user" &&
      location.pathname !== "/"
    )
      navigate("/"); // catch invalid attempts and return to frontpage

    fetchLocationData();
  }, []);

  return reveal ? (
    <Box
      sx={{
        /* flex: 1, */
        padding: "20px",
        marginBottom: extraSpace && phase !== 1 && "80px",
        boxSizing: "border-box",
        backgroundColor: "white",
      }}
    >
      <div style={{ position: "relative", flex: 1 }}>
        {phase !== 2 && (
          <ClassSelector
            selectedClass={selectedClass}
            setSelectedClass={setSelectedClass}
            location={data}
            skipPhase={handlePhaseSkip}
          />
        )}

        {selectedClass !== null &&
          selectedClass?.type == 0 &&
          phase == 0 && ( // fixes day selector flashing when location has no reservables and skipping phases
            /* data !== null &&
          data.has_products &&
          !data.has_reservations && */ <DaySelector
              passDateChange={setSelectedDate}
              selectedDate={selectedDate}
            />
          )}

        {selectedClass !== null && selectedClass?.type == 0 && phase == 0 && (
          <Timetable
            selectedDate={selectedDate}
            selectedClass={selectedClass}
            location={data}
            reservationClicked={reservationClicked}
            selectedReservations={selectedReservations}
            skipPhase={handlePhaseSkip}
          />
        )}

        {phase == 1 && (
          <ProductSelection
            addProduct={addProduct}
            removeProduct={removeProduct}
            selectedProducts={selectedProducts}
            location={data}
            isAfterReservation={selectedReservations.length > 0}
            selectedClass={selectedClass?.id}
            skipPhase={handlePhaseSkip}
          />
        )}

        {phase == 2 && (
          <Checkout
            selectedProducts={selectedProducts}
            selectedReservations={selectedReservations}
            location={data}
            selectedField={selectedField}
            returnBack={handleReturnBack}
          />
        )}
      </div>

      <BottomSheet
        selectedProducts={selectedProducts}
        selectedReservations={selectedReservations}
        phase={phase}
        selectedField={selectedField}
        eraseContent={eraseContent}
        nextPhase={nextPhase}
        checkoutVisible={handleExtraSpace}
      />
    </Box>
  ) : (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        textAlign: "center",
        marginTop: "10%",
      }}
    >
      {showHelperText ? <EmptyPage /> : <CircularProgress />}
    </div>
  );
};
