import React, { useContext } from "react";
import { Popover } from "@mui/material";
import { Box } from "@mui/system";
import Login from "./Login";
import { Logout } from "./Logout";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../Utils/UserContext";
import { GetScreenWidth } from "../../Utils/GetScreenWidth";

export const LoginPopover = ({
  open,
  handleClose,
  anchorEl,
  /* content */
  passLogin,
  data,
  passLogout,
}) => {
  const navigate = useNavigate();
  const { isLogged } = useContext(UserContext);

  // console.log(content, data);

  return (
    <Popover
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      anchorReference="anchorEl"
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Box
        sx={{
          padding: "20px",
          width: isMobile ? `${GetScreenWidth() * 0.85}px` : "340px",
          //minHeight: "135px",
          maxWidth: "340px",
          boxSizing: "border-box",
        }}
      >
        {isLogged ? (
          <Logout
            data={data}
            handleLogout={() => {
              // passLogout function handles redirect after clearing cookie
              passLogout();
              handleClose();
            }}
            handleRedirect={(page) => {
              navigate(page);
              handleClose();
            }}
            passLogin={passLogin}
          />
        ) : (
          <Login passLogin={passLogin} />
        )}
      </Box>
    </Popover>
  );
};
