import React, { useContext, useEffect, useState } from "react";
import { HOST } from "../../env";
import { ColorScheme } from "../../Utils/ColorScheme";
import { ApiContext } from "../../Utils/ApiContext";
import { CircularProgress } from "@mui/material";

export const OrderControl = ({ order, canCancel }) => {
  const debug = false;

  const { ApiCall } = useContext(ApiContext);
  const [paymentError, setPaymentError] = useState(false);
  const [noReservations, setNoReservations] = useState(false);
  const [hide, setHide] = useState(false);
  const [orderCancelled, setOrderCancelled] = useState(false);
  const [hasClicked, setHasClicked] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);

  const checkForReservations = () => {
    const check = order.order_contents.some(
      (reservable) => reservable.content_type === 2
    );
    return check;
  };

  const handleOrderCancel = (oid) => {
    if (!confirmCancel) {
      // user can't refund orders without confirming by pressing refund button twice
      setConfirmCancel(true);
      return;
    }

    ApiCall("POST", "payment/refund/" + oid, null)
      .then((response) => {
        if (debug) console.log(response);
        setOrderCancelled(true);
      })
      .catch((error) => {
        if (debug) console.log(error);
      });

    setConfirmCancel(false);
  };

  const processPayment = (props) => {
    window.open(props.href, "_self", "noopener,noreferrer");
  };

  const continuePayment = (value) => {
    ApiCall(
      "POST",
      `payment/new_payment/${value}?success=${HOST}/success?lid=${order.location_id}&cancel=${HOST}/cancel?lid=${order.location_id}`,
      null
    )
      .then((res) => processPayment(res))
      .catch((error) => {
        if (debug) console.log(error);
      });
  };

  const getTimeleft = () => {
    const targetTime = new Date(canCancel);
    const currentTime = new Date();

    const reservedUntil = new Date(order.created_at);
    const reservationTime = 30; // minutes

    reservedUntil.setMinutes(reservedUntil.getMinutes() + reservationTime);

    const diff =
      (!paymentError ? targetTime.getTime() : reservedUntil.getTime()) -
      currentTime.getTime();

    let remainingTime = diff;
    const hours = Math.floor(remainingTime / (1000 * 60 * 60));
    remainingTime -= hours * (1000 * 60 * 60);
    const minutes = Math.floor(remainingTime / (1000 * 60));
    remainingTime -= minutes * (1000 * 60);
    const seconds = Math.floor(remainingTime / 1000);

    if (diff < 0) return null;

    if (paymentError && diff < remainingTime) return null;

    return (
      (paymentError || hours < 1 ? "" : hours + ":") +
      String(minutes).padStart(2, "0") +
      ":" +
      String(seconds).padStart(2, "0")
    );
  };

  const [timeLeft, setTimeLeft] = useState(getTimeleft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(getTimeleft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  useEffect(() => {
    setConfirmCancel(false);

    if (!checkForReservations()) setNoReservations(true);
    if (!order.order_succesful) setPaymentError(true);
  }, []);

  useEffect(() => {
    if (timeLeft === null && checkForReservations()) setHide(true);
    else setHide(false);
  }, [timeLeft === null]);

  /*  useEffect(() => {
    console.log(timeLeft);
  }, [timeLeft]); */

  return (
    <div
      style={{
        display: hide ? "none" : "flex",
        alignItems: "center",
        gap: "15px",
      }}
    >
     {/*  {!noReservations && (
        <div>
          {orderCancelled ? (
            <div style={{ textAlign: "right" }}>Osto peruutettu</div>
          ) : (
            <>
              <div style={{ fontSize: "12px", textAlign: "right" }}>
                {!paymentError
                  ? "Peruutusmahdollisuus on vielä voimassa"
                  : "Varaus odottaa maksua vielä"}
              </div>
              <div style={{ fontSize: "12px", textAlign: "right" }}>
                {timeLeft}
              </div>
            </>
          )}
        </div>
      )} */}

      <div
        style={{
          width: "80px",
          height: "20px",
          border: `1px solid ${
            hasClicked && !confirmCancel
              ? ColorScheme.blueOpacity
              : ColorScheme.tolotechLightBlue
          }`,
          backgroundColor:
            hasClicked && !confirmCancel
              ? ColorScheme.blueOpacity
              : ColorScheme.tolotechBlue,
          color: "white",
          borderRadius: "4px",
          textAlign: "center",
          padding: "10px",
          cursor: "pointer",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          display: orderCancelled ? "none" : "flex",
        }}
        onClick={(event) => {
          event.stopPropagation();
          setHasClicked(true);
          paymentError
            ? continuePayment(order.order_id)
            : handleOrderCancel(order.order_id);
        }}
      >
        {hasClicked && !confirmCancel ? (
          <CircularProgress size="2rem" color="info" />
        ) : paymentError ? (
          "Maksa"
        ) : confirmCancel ? (
          "Vahvista peruutus"
        ) : (
          "Peru osto"
        )}
      </div>
    </div>
  );
};
