/* eslint-disable indent */
import React, { createContext, useContext } from "react";

import axios from "axios";
import { BASE_URL } from "../env";
import { UserContext } from "./UserContext";

import { useNavigate } from "react-router-dom";

export const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
  const navigate = useNavigate();
  const { setIsLogged } = useContext(UserContext);

  const ApiCall = (method, url, body, signal) => {
    // console.log(method, " ", url, "signal: ", signal);

    // aconsole.log(url);

    return new Promise((resolve, reject) => {
      let token = document.cookie.split("token-booking=")[1];
      let route_url = "";
      let unauth_url = "unauthorized/";

      if (token !== undefined) {
        // if booking token is found set user as logged in
        token = token.split(";")[0];
        setIsLogged(true);
      } else {
        // if booking token is not found set user as logged out
        setIsLogged(false);
      }

      // console.log(url);

      if (
        token === undefined &&
        url !== "user" &&
        url !== "login" &&
        url !== "code_login" &&
        url !== "user/reset_password" // fixed route filter
      ) {
        // ignore user, login, code_login and user_reset_password routes since there is no need for unauth route for them

        const substringToRemove = "location/get_purchaseable_location/";
        const substringToAdd = "get_purchaseable_location/";

        if (url.startsWith(substringToRemove)) {
          route_url = url.replace(substringToRemove, "");
          route_url = unauth_url + substringToAdd + route_url;
        } else route_url = route_url.concat(unauth_url, url); // adds unauth prefix to route
      } else {
        route_url = route_url.concat(url); // adds route without unauth prefix
      }

      const splitted_url = route_url.split("/");

      //console.log(splitted_url);

      let route = "";

      for (let i = 0; i < splitted_url.length; i++) {
        let isnum = /^\d+$/.test(splitted_url[i]);
        if (!isnum) route = route + (i !== 0 ? "_" : "") + splitted_url[i];
      }

      // console.log(route);
      // console.log("Fetching: " + BASE_URL + url);

      /* Vanha kirjautumistiedon tarkistus ja routejen rajaus.
     if (
        token === undefined &&
        route !== "login" &&
        route !== "user" &&
        route !== "code_login" &&
        route !== "user_reset_password"
      ) {
        return;
      } */

      // console.log(route_url);

      axios({
        method,
        url: BASE_URL + route_url,
        signal,
        data: body,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          // console.log(err.response?.data?.error);
          
          if (route_url !== "user" && err?.response?.status === 401) {
            document.cookie =
              "token-booking=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
            navigate("/");
            setIsLogged(false);
          }

          // TODO: erase cookies and route to login if status code is 401
          // navigate("/login");
          /* if (err.response.status === 401 && navigate !== null) navigate("/"); */

          reject(err);
        });
    });
  };

  return (
    <ApiContext.Provider
      value={{
        ApiCall,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};
