import { Box, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
// import { ColorScheme } from "../../Utils/ColorScheme";
import moment from "moment";
import { ArrowButton } from "../Buttons/ArrowButton";
import { ColorScheme } from "../../Utils/ColorScheme";

const weekdaysFI = [
  "Sunnuntai",
  "Maanantai",
  "Tiistai",
  "Keskiviikko",
  "Torstai",
  "Perjantai",
  "Lauantai",
];

const CalendarPaperStyle = {
  /* backgroundColor: ColorScheme.tolotechWhite,
  color: ColorScheme.tolotechDarkBlue, */
  borderRadius: "10px",
  overflow: "hidden",
  "& .MuiPickersBasePicker-pickerView": {
    borderRadius: "6px",
  },
  "& .MuiCalendarPicker-root": {
    backgroundColor: ColorScheme.tolotechWhite,
  },
  "& .MuiPickersDay-dayWithMargin": {
    fontWeight: 700,
    color: ColorScheme.black,
    backgroundColor: "transparent",
  },
  "& .MuiTabs-root": { backgroundColor: "rgba(120, 120, 120, 0.4)" },
  "& .MuiPickersDay-daySelected": {
    backgroundColor: ColorScheme.tolotechDarkBlue,
    color: ColorScheme.tolotechWhite,
    fontWeight: 700,
  },
  "& .Mui-selected": {
    backgroundColor: ColorScheme.tolotechDarkBlue,
    color: ColorScheme.tolotechWhite,
  },
  "& .MuiPickersDay-dayDisabled": {
    color: ColorScheme.veryLightGrey,
    backgroundColor: "transparent",
    fontWeight: 700,
  },
  "& .MuiPickersCalendarHeader-label": {
    color: ColorScheme.black,
    fontWeight: 700,
    textTransform: "capitalize",
  },
  "& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected": {
    backgroundColor: ColorScheme.tolotechDarkBlue,
  },
  "& .MuiDayPicker-weekDayLabel": {
    color: "#B4B4B4",
    fontWeight: 700,
  },
};

function DaySelector({ passDateChange, selectedDate }) {
  const [selectedDay, setSelectedDay] = useState(null);
  const [open, setOpen] = useState(false);

  const displayDate = new Date(selectedDay);

  const handleDayChange = (props) => {
    if (props === undefined) return;
    else setSelectedDay(props);
  };

  const handleClick = (event) => {
    event.preventDefault();
    setOpen(!open);
  };

  useEffect(() => {
    if (selectedDay !== null) passDateChange(selectedDay);
  }, [selectedDay]);

  useEffect(() => {
    if (selectedDay === null && selectedDate === null)
      setSelectedDay(new Date(Date.now())); // set current day as default
    else setSelectedDay(selectedDate); // keeps user selected day active instead of using current day
  }, []);

  return (
    <Box
      onClick={() => {
        if (open) return;
        setOpen(!open);
      }}
    >
      <DatePicker
        disableHighlightToday
        dayOfWeekFormatter={(dayOfWeek, label) => {
          return dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
        }}
        disableMaskedInput
        minDate={new Date()}
        value={selectedDay}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        closeOnSelect
        onChange={(date) => {
          handleDayChange(date);
        }}
        PaperProps={{
          sx: CalendarPaperStyle,
        }}
        showToolbar={false}
        renderInput={(params) => {
          return (
            <Box
              ref={params.inputRef}
              sx={{
                "& .MuiOutlinedInput-input": {
                  textAlign: "center",
                  border: "1px #F0F0F0 solid",
                },
                display: "flex",
                fontWeight: 600,
                color: ColorScheme.tolotechDarkBlue,
                backgroundColor: "#F0F0F0",
                border: "1px #DCDCDC solid",
                borderRadius: "6px",
                boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.05)",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                /*  top: "50%", */
                height: "48px",
                margin: "auto",
                marginTop: "10px",
                marginBottom: "20px",
                cursor: "pointer",
                zIndex: 1600,
              }}
              onClick={(e) => handleClick(e)}
            >
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                  handleDayChange(
                    moment(selectedDay).subtract(1, "day").format()
                  );
                }}
                style={{ height: "100%", width: "50px" }}
              >
                <ArrowButton direction="left" />
              </Box>
              <Typography
                fontSize="14px"
                fontWeight="700"
                color={ColorScheme.tolotechDarkBlue}
              >
                {weekdaysFI[displayDate.getDay()]}{" "}
                {displayDate.getDate() +
                  "." +
                  (displayDate.getMonth() + 1) +
                  "." +
                  displayDate.getFullYear()}
              </Typography>
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                  handleDayChange(moment(selectedDay).add(1, "day").format());
                }}
                style={{ height: "100%", width: "50px" }}
              >
                <ArrowButton direction="right" />
              </Box>
            </Box>
          );
        }}
      />

      {open && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 5,
          }}
        />
      )}
    </Box>
  );
}

export default DaySelector;
