import React, { useContext, useEffect, useState } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { ApiContext } from "../../Utils/ApiContext";
import { ColorScheme } from "../../Utils/ColorScheme";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "../../Styles/Transitions.css";
import "./ProductContent.css";

//  import { UserContext } from "../../Utils/UserContext";

export const ProductSelection = ({
  selectedProducts,
  location,
  addProduct,
  removeProduct,
  isAfterReservation,
  selectedClass,
  skipPhase
}) => {
  const { ApiCall } = useContext(ApiContext);

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  const checkIfMax = (product) => {
    const max = product.max_amount;
    const selected = selectedProducts.filter((item) => item.id === product.id);
    return selected.length >= max;
  };

  const fetchProducts = () => {
    setLoading(true);

    ApiCall("GET", `location/get_products/${location.location_id}`, null) // ?type=${classType.id}
      .then((res) => {
        // Sort the array based on the class type
       /*  res.sort((a, b) => {
          if (selectedClass === a.service_class.id) return -1;
          if (selectedClass === b.service_class.id) return 1;
          return 0;
        }); */

        setProducts(res);
        if (res.length === 0) skipPhase(2); // If no products, skip to next phase
        setLoading(false)
      })
      .catch(() => {
        setLoading(false);
      });
  };

 /*  useEffect(() => {
    if (products.length > 0) {
      setLoading(false);
    }
  }, [products]); */

  useEffect(() => {
    setProducts([]);
    setLoading(true);
    fetchProducts();
  }, [selectedClass]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "rgb(255, 255, 255)",
        /* paddingX: "20px", */
        paddingBottom: "20px",
        width: "100%",
      }}
    >
      <Typography
        sx={{
          alignSelf: "flex-start",
          marginTop: "30px",
          fontSize: "24px",
          fontWeight: "700",
        }}
      >
        Tuotteet
      </Typography>
      <TransitionGroup style={{width: "100%"}}>
        {products.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              backgroundColor: ColorScheme.veryLightGrey,
              borderRadius: "4px",
              boxShadow: " 0px 0px 30px 2px rgba(0,0,0,0.07);",
              border: "1px solid #D9D9D9",
              marginTop: "20px",
              boxSizing: "border-box",
              marginBottom:
                (selectedProducts?.length > 0 || isAfterReservation) && "150px",
            }}
          >
            {products.map((product, index) => {
              return (
               /*  <CSSTransition
                  key={index}
                  classNames="fade" // This should match your animation name in ProductContent.css
                  timeout={300}
                  appear={true}
                  in={true}
                > */
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      height: "110px",
                      boxSizing: "border-box",
                      borderBottom:
                        index !== products.length - 1 && "1px solid #D9D9D9",
                    }}
                    key={index}
                  >
                    <Box
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        display: "flex",
                        padding: "15px",
                      }}
                    >
                      <img
                        alt={product.name}
                        src={product?.image?.image}
                        style={{
                          height: 80,
                          width: 80,
                          objectFit: "cover",
                          borderRadius: "4px",
                        }}
                      />

                      <Stack
                        direction={"column"}
                        sx={{
                          width: "40%",
                          justifyContent: "space-between",
                          flex: 1,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            width: "calc(100% - 45px)",
                            paddingLeft: "20px",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "black",
                              fontSize: "16px",
                              margin: "0px",
                              fontWeight: "700",
                            }}
                          >
                            {product.name}
                          </Typography>
                          <Typography
                            sx={{
                              color: "#505050",
                              fontSize: "12px",
                              fontWeight: "500",
                              margin: "0px",
                              lineHeight: "0.9",
                              display: "-webkit-box",
                              overflow: "hidden",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 3,
                            }}
                          >
                            {product.description}
                          </Typography>
                          <Stack
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "flex-end",
                              marginTop: "5px",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "16px", fontWeight: "700" }}
                            >
                              {product.vat_price + " €"}
                            </Typography>
                            <Typography
                              sx={{
                                // marginLeft: "12px",
                                color: "#505050",
                                fontSize: "10px",
                                fontWeight: "500",
                                marginLeft: "5px",
                                marginBottom: "2px",
                              }}
                            >
                              {"Alv. " + product.vat_percentage + "%"}
                            </Typography>
                          </Stack>
                        </div>
                      </Stack>
                    </Box>
                    <Box
                      sx={{
                        width: "15%",
                        alignItems: "center",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        borderLeft: "1px solid #D9D9D9",
                        position: "relative",
                      }}
                    >
                      <Box
                        style={{
                          position: "absolute",
                          top: "calc(50% - 15px)",
                          left: "-15px",
                        }}
                      >
                        <CSSTransition
                          unmountOnExit
                          timeout={300}
                          in={selectedProducts.some(
                            (item) => item.id === product.id
                          )}
                          classNames="scale"
                        >
                          <Box
                            sx={{
                              backgroundColor: ColorScheme.tolotechDarkBlue,
                              width: "30px",
                              height: "30px",
                              borderRadius: "20px",
                              justifyContent: "center",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              fontSize={"15px"}
                              color={"white"}
                              fontWeight={700}
                              lineHeight={0}
                            >
                              {
                                selectedProducts.filter(
                                  (item) => item.id === product.id
                                ).length
                              }
                            </Typography>
                          </Box>
                        </CSSTransition>
                      </Box>
                      <Box
                      
                        onClick={() => checkIfMax(product) ? null : addProduct(product)}
                        sx={{
                          width: "100%",
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          margin={"0px"}
                          color={checkIfMax(product) ? ColorScheme.blackOpacity : "#2F9C1D"}
                          fontSize={"36px"}
                        >
                          +
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          width: "100%",
                          height: 1,
                          backgroundColor: "#D9D9D9",
                        }}
                      />
                      <Box
                        onClick={() => removeProduct(product)}
                        sx={{
                          width: "100%",
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Typography color={"#C53939"} fontSize={"36px"}>
                          -
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
               
              );
            })}
          </Box>
        )}
     </TransitionGroup>

      {loading && (
        <CircularProgress
          sx={{
            marginTop: "25%",
            zIndex: 99,
            overflow: "hidden",
          }}
        />
      )}

      {!loading && products.length == 0 && (
        <Typography  sx={{ marginTop: "50px" }} >Ei tuotteita</Typography>
      )}
    </Box>
  );
};
