import React, { /* useContext */ useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
// import { CSSTransition } from "react-transition-group";
////  import { UserContext } from "../Utils/UserContext";
import { ReactComponent as LocationMarker } from "../../Assets/LocationMarker.svg";
import { GetScreenWidth } from "../../Utils/GetScreenWidth";
import { CardText } from "../Calendar/CardText";
import { ApiContext } from "../../Utils/ApiContext";
import { CSSTransition } from "react-transition-group";

export const LocationCard = ({ data }) => {
  const { ApiCall } = useContext(ApiContext);
  //  const { isLogged } = useContext(UserContext); */
  const navigate = useNavigate();
  const screenWidth = GetScreenWidth();

  /*   const debug = false; */

  // const [imageUrl /* setImageUrl */] = useState(data.logo_image?.image);

  const [banner, setBanner] = useState(null);
  /* const [newImageUrl, setNewImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false); */
  /*   const [showOldImage, setShowOldImage] = useState(true); */
  const [showHoverEffect, setShowHoverEffect] = useState(false);
  const [cardTextHeight, setCardTextHeight] = useState("auto");
  const [smaller, setSmaller] = useState(false);

  const handleClick = (value) => {
    navigate(`/location/${value.location_id}`); // , { state: { value } } with value as state to pass
  };

  const cardStyle = {
    borderRadius: "4px",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.12)",
    width: isMobile ? "45%" : "200px",
    height: smaller ? "200px" : "180px",
    margin: "4px",
  };

  const cardHoverStyle = {
    boxShadow: "0 0 40px rgba(0, 0, 0, 0.15)",
    cursor: "pointer",
    transform: "scale(1.05)",
    transition: "all .2s ease-out",
  };

  /*  const apiRoute = isLogged
    ? "location/get_images"
    : "unauthorized/location/get_images"; */

  const fetchBanner = () => {
    ApiCall("GET", `location/get_images/${data.location_id}?type=2`, null)
      .then((response) => {
        if (response.length > 0) {
          setBanner(response[0].image);
        } else return;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*  useEffect(() => {
    if (newImageUrl) {
      setShowOldImage(true);

      setTimeout(() => {
        setShowOldImage(false);
        setImageUrl(newImageUrl);
      }, 2500);
    }
  }, [newImageUrl]); */

  useEffect(() => {
    if (screenWidth < 400) setSmaller(true);

    const calculateCardTextHeight = () => {
      const cardTextContainer = document.getElementById(
        `card-text-${data.location_id}`
      );
      if (cardTextContainer) {
        const cardTextHeight = cardTextContainer.offsetHeight + "px";
        setCardTextHeight(cardTextHeight);
      }
    };

    calculateCardTextHeight();
  }, []);

  useEffect(() => {
    fetchBanner();
  }, []);

  return (
    <div
      onClick={() => handleClick(data)}
      key={data.location_id}
      style={{
        ...cardStyle,
        ...(showHoverEffect && cardHoverStyle),
      }}
      onMouseEnter={() => setShowHoverEffect(true)}
      onMouseLeave={() => setShowHoverEffect(false)}
    >
      <div
        style={{
          height: `calc(100% - ${cardTextHeight})`,
          width: "100%",
          display: "flex",

          justifyContent: "space-around",
          alignItems: "center",
          overflow: "hidden",
          borderRadius: "4px 4px 0px 0px",
          border: "0px",
        }}
      >
        {/*  <CSSTransition
          in={showOldImage}
          timeout={500}
          classNames="fade"
          unmountOnExit
        > */}
        <CSSTransition
          classNames="fade-in-scale-down"
          timeout={700}
          unmountOnExit
          in={banner != null}
        >
          <img
            alt=""
            style={{
              objectFit: /* showOldImage ? */ "cover" /* : "contain" */,
              width: "100%",
              height: "100%",
            }}
            src={
              banner != null
                ? banner
                : "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
            }
          />
        </CSSTransition>
        {/*   </CSSTransition> */}

        {/*  <CSSTransition
          in={!showOldImage && !isLoading}
          timeout={500}
          classNames="fade"
          unmountOnExit
        >
          <img
            alt=""
            style={{
              objectFit: "cover",
            }}
            src={newImageUrl}
          />
        </CSSTransition>*/}
      </div>
      <div
        id={`card-text-${data.location_id}`}
        style={{
          padding: "12px",
          borderTop: "1px solid rgba(0, 0, 0, 0.03)",
        }}
      >
        <CardText
          text={data.name}
          maxLines={2}
          lineHeight={20}
          fontSize={"16px"}
          fontWeight={"bold"}
        />

        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <CardText
            color={"rgba(0,0,0,0.5)"}
            text={data.address}
            maxLines={2}
            lineHeight={14}
            fontSize={"11px"}
            fontWeight={400}
          />
        </div>
      </div>
    </div>
  );
};
