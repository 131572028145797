import React, { useEffect, useState } from "react";
import "../../Styles/Transitions.css"; // Import CSS styles for transitions

export const ButtonSlider = ({ handleContent }) => {
  const [activeButton, setActiveButton] = useState(0);

  const numberOfButtons = 3;
  const width = `calc(100% / ${numberOfButtons})`;
  const buttonPosition = `calc((100% / ${numberOfButtons}) * ${activeButton})`;

  const handleButtonClick = (buttonIndex) => {
    setActiveButton(buttonIndex);
  };

  useEffect(() => {
    // console.log(activeButton);
    handleContent(activeButton);
  }, [activeButton]);

  return (
    <div
      className="button-slider-container"
      style={{
        marginBottom: "20px",
      }}
    >
      <div
        style={{ width: width }}
        className={`button-slider-button-left ${
          activeButton === 0 ? "active" : ""
        }`}
        onClick={() => handleButtonClick(0)}
      >
        Varaukset
      </div>

      <div
        style={{ width: width }}
        className={`button-slider-button-middle ${
          activeButton === 1 ? "active" : ""
        }`}
        onClick={() => handleButtonClick(1)}
      >
        Saldo
      </div>

      <div
        style={{ width: width }}
        className={`button-slider-button-right ${
          activeButton === 2 ? "active" : ""
        }`}
        onClick={() => handleButtonClick(2)}
      >
        Ostot
      </div>

      <div
        className="button-slider-highlight"
        style={{
          width: width,
          left: buttonPosition,
        }}
      />
    </div>
  );
}; 
