import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
//  import { UserContext } from "../../Utils/UserContext";
import { ApiContext } from "../../Utils/ApiContext";
import { ToFrontpage } from "../Buttons/ToFrontpage";
import { UserLoginButton } from "../Buttons/UserLoginButton";
import { LoginPopover } from "../Login/LoginPopover";
import SVG from "react-inlinesvg";
import { ColorScheme } from "../../Utils/ColorScheme";
import { GetScreenWidth } from "../../Utils/GetScreenWidth";
import { UserContext } from "../../Utils/UserContext";
// import tologoLogo from "../../Assets/dark_primary_logo_new.png";

import "./LocationInfo.css";

const LocationInfo = ({ location, passLogout, passLogin, isLoading }) => {
  const { ApiCall } = useContext(ApiContext);
  const { setIsLogged } = useContext(UserContext);

  const screenWidth = GetScreenWidth();

  const userLocation = useLocation();
  const currentLocation = userLocation.pathname;
  // const atOrders = "/user/orders";

  /* const locationLogo = location?.logo_image?.image; */

  const [user, setUser] = useState(null);
  const [openPopover, setOpenPopover] = useState(null);
  const [slide, setSlide] = useState(false);
  const [iconSvg, setIconSvg] = useState(null);
  const [hide, setHide] = useState(false);
  const [smaller, setSmaller] = useState(false);

  const [reveal, setReveal] = useState(false);

  const handleLogout = () => {
    setUser(null);
    setIsLogged(false);
    passLogout();
  };

  const fetchUserData = () => {
    ApiCall("GET", "user")
      .then((user) => {
        setUser(user);
        passLogin(true);
      })
      .catch(() => {
        setUser(null);
        passLogin(false);
      });
  };

  function modifySvg(svgString, stroke, strokeWidth) {
    const styleString = `stroke="${stroke}" stroke-width="${strokeWidth}"`;

    let newString = svgString.replace("<svg", `<svg ${styleString}`);
    return newString;
  }

  const open = Boolean(openPopover); // returns true if openPopover is not null

  const handlePopover = (props) => {
    setOpenPopover(props.currentTarget); // defines anchor point
  };

  const handleClose = () => {
    setOpenPopover(null);
  };

  const handleUserLogin = (prop) => {
    if (prop) fetchUserData();
    passLogin(prop);
  };

  useEffect(() => {
    /* setSlide(currentLocation === atOrders); */
    if (currentLocation === "/") setIconSvg(null);
    setHide(currentLocation === "/");
    setSlide(currentLocation !== "/");
  }, [userLocation]);

  useEffect(() => {
    if (
      location !== null &&
      location?.logo_image &&
      location.logo_image.image
    ) {
      setIconSvg(location.logo_image.image);
      let svg = modifySvg(location.logo_image.image, ColorScheme.black, "1.5");
      setIconSvg(svg);
    }
  }, [location && iconSvg === null]);

  useEffect(() => {
    if (screenWidth < 400) setSmaller(true);
    fetchUserData();
  }, []);

  useEffect(() => {
    if (location === null) {
      setReveal(false);
    }
  }, [location]);

  useEffect(() => {
    if (!reveal && location !== null) {
      const timeout = setTimeout(() => {
        setReveal(true);
      }, 250);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [reveal, location]);

  return (
    <Box
      style={{
        flexDirection: "row",
        display: "flex",
        height: "70px",
        //flex: 1,
        padding: "20px",
        boxSizing: "border-box",
        alignItems: "center",
        borderBottom: "1px solid rgba(0,0,0,0.05)",
        //marginBottom: "5px",
        transition: "transform 2s",
      }}
    >
      <div
        style={{
          position: "absolute",
          left:
            (currentLocation === "/user" || currentLocation === "/notifications") && location === null ? "20px" : "-100%",
          transition: "left 0.5s",
        }}
      >
        <div style={{ minWidth: "35px" }}>
          <ToFrontpage />
        </div>
      </div>
      {/* Added back button to /user page if user goes there directly from frontpage */}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          transition: "transform 0.5s",
          width: reveal ? "100%" : 0,

          transform:
            reveal && location !== null ? "translateX(0)" : "translateX(-100%)",
          opacity: reveal && location !== null ? 1 : 0,
        }}
      >
        <ToFrontpage
          path={
            (currentLocation === "/user" || currentLocation === "/notifications") && `/location/${location?.location_id}`
            // This allows the user to go back one step from the ´/user´ and ´/notifications´ page
            // to the selected location's calendar view instead of jumping back to the front page.
            // If the user is already in the calendar view, it will go back to the front page.
          }
        />
        <Box
          sx={{
            padding: smaller ? "2px" : "10px",
            minHeight: "40px",
            maxHeight: "40px",
            minWidth: "40px",
            maxWidth: "40px",
            borderRadius: smaller ? "2px" : "10px",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "center",
            display: Boolean(iconSvg) ? "flex" : "none",
            /* display: "none", */
            overflow: "hidden",
            filter: "opacity(100%)",
          }}
        >
          <img
            alt=""
            style={{
              height: smaller ? "40px" : "40px",
              width: smaller ? "40px" : "40px",
              objectFit: "cover",
              borderRadius: "4px",
            }}
            src={iconSvg}
          />
          <SVG src={iconSvg} width={20} height={20} title="logo" />
        </Box>

        <Box
          style={{
            flex: 1,
            marginLeft: smaller ? "10px" : "15px",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              color: ColorScheme.black,
              fontWeight: "bold",
              fontFamily: "Sofia Sans",
              marginBottom: -0.5,
              maxWidth: smaller ? "85%" : undefined,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {location?.name}
          </Typography>
          <Typography
            sx={{
              fontSize: "10px",
              color: ColorScheme.black,
              opacity: 0.8,
              fontWeight: "700",
              margin: "0px",
            }}
          >
            {location?.address}
          </Typography>
        </Box>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: location !== null && !hide ? 0 : "100%",
          overflow: "hidden",
          opacity: location !== null && !hide ? 0 : 1,
          paddingLeft:
            (currentLocation === "/user" || currentLocation === "/notifications") && location === null ? "50px" : "10px",
          alignItems: "left",
          transition:
            "padding-left 1s, opacity 0.2s linear, width 0.5s, transform 0.5s",
        }}
      >
        {/*  <img
            src={tologoLogo}
            alt="Tolotech"
            width="24px"
            height="24px"
            style={{ paddingRight: "10px" }}
          /> */}
        <Typography
          fontSize="34px"
          fontWeight="700"
          color={ColorScheme.tolotechBlue}
        >
          ToloGo
        </Typography>
      </div>

      {location !== null && <div style={{ flex: 1 }} />}

      <Box
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          cursor: "pointer",
          right: "20px",
          // gap: "5px",
        }}
      >
        <UserLoginButton handlePopover={handlePopover} user={user} />
      </Box>

      <LoginPopover
        open={open}
        data={user}
        /* content={isLogged} */
        handleClose={handleClose}
        anchorEl={openPopover}
        passLogin={handleUserLogin}
        passLogout={handleLogout}
      />
    </Box>
  );
};

export default LocationInfo;
