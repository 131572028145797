import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../Utils/ApiContext";
import { ButtonSlider } from "../Buttons/ButtonSlider";
import { UserOrders } from "./UserOrders";
import { UserReservations } from "./UserReservations";
import { UserBalance } from "./UserBalance";
import UserInfoBox from "./UserInfoBox";

export const UserManagement = () => {
  const { ApiCall } = useContext(ApiContext);
  const [content, setContent] = useState("");
  const [allLocations, setAllLocations] = useState([]);

  const fetchLocations = () => {
    ApiCall("GET", `locations`, null)
      .then((response) => setAllLocations(response))
      .catch((error) => console.log(error));
  };

  const switchContent = (id) => {
    setContent(id);
  };

  const getContent = (id) => {
    // console.log("switch", id);
    switch (id) {
      case 0:
        return <UserReservations allLocations={allLocations} />;
      case 1:
        return <UserBalance />; 
      case 2:
        return <UserOrders allLocations={allLocations} />;

      default:
        return null; // or blank/error page
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  return (
    <div style={{ padding: "20px" }}>

     <UserInfoBox />

      <ButtonSlider handleContent={switchContent} />

      {getContent(content)}
    </div>
  );
};
