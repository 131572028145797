import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
// import { ColorScheme } from "../../Utils/ColorScheme";
import { CardLabels } from "./CardLabels";
import { OrderContent } from "./OrderContent";

export const OrderCard = ({
  order,
  index,
  date,
  start,
  end,
  locations,
  isLoading,
}) => {
  const isOrder = Boolean(order.order_id);

  const [collapsedItems, setCollapsedItems] = useState({});

  const toggleCollapse = (id) => {
    setCollapsedItems((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FAFAFA",

        /* boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.05)", */
        /*  borderRadius: "4px", */
        /* marginBottom: "4px", */
        borderTop: index === 0 ? undefined : "1px solid #D9D9D9",
        padding: "10px",
        // filter: isLoading ? "blur(2px)" : "none", // add text blur if isLoading is true
        transition: "all 0.2s ease-in-out",
        cursor: isOrder ? "pointer" : "default",
      }}
    >
      <div
        style={{ cursor: "pointer" }}
        onClick={
          !isOrder
            ? undefined
            : () => {
                toggleCollapse(order.order_id);
              }
        }
      >
        <CardLabels
          date={date}
          start={start}
          end={end}
          order={order}
          isCollapsed={collapsedItems[order.order_id]}
        />
      </div>

      <CSSTransition
        unmountOnExit
        timeout={500}
        in={collapsedItems[order.order_id]}
        classNames={"collapsed-drawer"}
      >
        <OrderContent order={order} locations={locations} />
      </CSSTransition>
    </div>
  );
};
