import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Checkbox,
  CircularProgress,
  Collapse,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { ApiContext } from "../../Utils/ApiContext";
import { ColorScheme } from "../../Utils/ColorScheme";
import "../../Styles/Transitions.css";
import { UserContext } from "../../Utils/UserContext";
import moment from "moment";
import { BASE_URL } from "../../env";
//  import { UserContext } from "../../Utils/UserContext";

import { HOST } from "../../env";

const days = [
  "Sunnuntai",
  "Maanantai",
  "Tiistai",
  "Keskiviikko",
  "Torstai",
  "Perjantai",
  "Lauantai",
];

export const Checkout = ({
  selectedProducts,
  location,
  selectedReservations,
  selectedField,
  returnBack,
}) => {
  const [termsAccepted, setTermsAccepted] = useState(false);

  const { ApiCall } = useContext(ApiContext);
  const { isLogged } = useContext(UserContext);

  const [user, setUser] = useState(null);

  const [orderEmail, setOrderEmail] = useState(null);
  const [campaignCode, setCampaignCode] = useState("");
  const [codeActivated, setCodeActivated] = useState(false);

  const [discountedReservations, setDiscountedReservations] = useState([]);
  const [discountedProducts, setDiscountedProducts] = useState([]);
  const [campaignCodeId, setCampaignCodeId] = useState(null);

  const [loadingNewOrder, setLoadingNewOrder] = useState(false);

  const [originalPrices, setOriginalPrices] = useState({});
  const [discountType, setDiscountType] = useState({});

  const [invalidCode, setInvalidCode] = useState(false);

  const [useBalance, setUseBalance] = useState(false);
  const [balance, setBalance] = useState(null);
  const [showError, setShowError] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const getDiscountLabel = (value) => {
    switch (value) {
      case 0: // for discount in currency
        return "Euromääräinen alennus " /* `"${discountedArray.code}"  ` */;
      case 1:
        return "Muut ";
      case 2:
        return "Varaukset ";
      case 3:
        return "Tuotteet ";
      case 4:
        return "Kurssit ";
      case 5:
        return "Kaikki ";
      default:
        break;
    }
  };

  function calculateDuration(resevation) {
    if (discountedReservations.length === 0) return;

    let date = new Date(resevation.from_time);

    let string =
      days[date.getDay()] + " " + date.getDate() + "." + (date.getMonth() + 1);

    return (
      string +
      " " +
      `${moment(resevation.from_time).format("HH:mm")} - ${moment(
        resevation.to_time
      ).format("HH:mm")}`
    );
  }

  function getTotalPrice() {
    let total = 0;
    for (var i = 0; i < discountedProducts.length; i++) {
      total += discountedProducts[i].vat_price;
    }
    for (var i = 0; i < discountedReservations.length; i++) {
      total += discountedReservations[i].vat_price;
    }
    return total;
  }

  const handleCodeCheck = () => {
    const reservation_ids = [];
    const product_ids = [];

    discountedReservations?.map((reservation) => {
      reservation_ids.push(reservation.reservation_id);
    });

    discountedProducts?.map((product) => {
      product_ids.push(product.id);
    });

    const body = {
      location_id: location.location_id,
      reservation_ids,
      product_ids,
    };

    ApiCall("POST", `campaign/validate/${campaignCode}`, body)
      .then((res) => {
        // console.log(res);
        setInvalidCode(false);
        setDiscountType({
          discount_type: res.discount_type,
          discount_euros: res.discount_euros,
          discount_percent: res.discount_percent,
        });

        var tempDiscountedReservations = [];
        var tempDiscountedProducts = [];
        for (var i = 0; i < res.order.order_contents.length; i++) {
          if (res.order.order_contents[i].content_type === 2) {
            let reservation = discountedReservations.find(
              (reservation) =>
                reservation.reservation_id ===
                res.order.order_contents[i].content_id
            );
            let tempRes = { ...reservation };
            tempRes.vat_price = res.order.order_contents[i].vat_price;
            tempDiscountedReservations.push(tempRes);
          } else if (res.order.order_contents[i].content_type === 3) {
            let product = discountedProducts.find(
              (product) => product.id === res.order.order_contents[i].content_id
            );
            let tempProd = { ...product };
            tempProd.vat_price = res.order.order_contents[i].vat_price;
            tempDiscountedProducts.push(tempProd);
          }
        }

        setDiscountedProducts(tempDiscountedProducts);
        setDiscountedReservations(tempDiscountedReservations);
        setCodeActivated(true);

        setCampaignCodeId(res.id);
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(err.response.data.error);
        setInvalidCode(true);
      });
  };

  function resetCode() {
    setDiscountedProducts(selectedProducts);
    setDiscountedReservations(selectedReservations);
    setCampaignCode("");
    setCampaignCodeId(null);
    setCodeActivated(false);
    setInvalidCode(false);
  }

  useEffect(() => {
    setDiscountedProducts(selectedProducts);
    setDiscountedReservations(selectedReservations);

    setOriginalPrices({
      products: selectedProducts,
      reservations: selectedReservations,
    }); // Save original prices for later use
  }, []);

  function isEmailValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  const processPayment = (props) => {
    window.open(props.href, "_self", "noopener,noreferrer");
  };

  const handleSubmit = () => {
    setLoadingNewOrder(true);

    let reservationIds = [];
    for (var i = 0; i < discountedReservations.length; i++) {
      reservationIds.push(discountedReservations[i].reservation_id);
    }

    let productIds = [];
    for (var i = 0; i < discountedProducts.length; i++) {
      productIds.push(discountedProducts[i].id);
    }

    const body = {
      ...(!isLogged && { email: orderEmail }), // if user is not logged in, include unauthed user inputted email
      reservation_ids: reservationIds,
      product_ids: productIds,
      location_id: location.location_id,
      campaign_id: codeActivated ? campaignCodeId : undefined,
      total_price: getTotalPrice(),
    };

    // console.log(body);

    ApiCall("POST", `user/order/new`, body)
      .then((response) => {
        // console.log(response);
        ApiCall(
          "POST",
          `payment/new_payment/${response.order_id}?success=${HOST}/success?lid=${response.location_id}&cancel=${HOST}/cancel?lid=${response.location_id}&use_balance=${useBalance}`,
          null
        )
          .then((res) => {
            setLoadingNewOrder(false);
            processPayment(res);
          })
          .catch((err) => {
            setShowError(err?.response?.data?.error);
            setLoadingNewOrder(false);
          });
      })
      .catch((error) => {
        setLoadingNewOrder(false);
        setShowError(error?.response?.data?.error);

        console.log(error);
        if (
          !isLogged &&
          String(error.response.data.error).startsWith(
            "550 The mail server could not deliver mail"
          )
        )
          setInvalidEmail(true);
        else setInvalidEmail(false);
      });
  };

  const checkUserBalance = () => {
    ApiCall("GET", `user/balances?location=${location.location_id}`, null)
      .then((res) => {
        // console.log(res);
        setBalance(res[0]?.amount || 0); // if server returns empty response, set balance to 0 to hide it from interface
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    ApiCall("GET", "user", null)
      .then((res) => {
        setUser(res);
      })
      .catch((err) => {
        console.log(err);
      });

    if (isLogged) {
      checkUserBalance();
    }
  }, [isLogged]);

  useEffect(() => {
    if (!isLogged && invalidEmail) {
      setInvalidEmail(false);
    }
  }, [orderEmail]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "rgb(255, 255, 255)",
        /* paddingX: "20px", */
        paddingBottom: "20px",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Typography
        sx={{
          alignSelf: "flex-start",
          fontSize: "24px",
          fontWeight: "700",
        }}
      >
        Yhteenveto
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          backgroundColor: ColorScheme.veryLightGrey,
          borderRadius: "4px",
          boxShadow: " 0px 0px 30px 2px rgba(0,0,0,0.07);",
          border: "1px solid #D9D9D9",
          marginTop: "20px",
          boxSizing: "border-box",
          padding: "20px",
        }}
      >
        {isLogged && user != null && (
          <Box style={{ marginBottom: "30px" }}>
            <Typography style={{ fontSize: "16px", fontWeight: "700" }}>
              {user.name}
            </Typography>
            <Typography
              fontSize={"12px"}
              fontWeight={700}
              color={ColorScheme.grey}
            >
              {user.email}
            </Typography>
          </Box>
        )}

        {selectedField != null && (
          <Box>
            <Typography style={{ fontSize: "16px", fontWeight: "700" }}>
              {selectedField.name}
            </Typography>
            {discountedReservations.map((reservation, index) => {
              return (
                <Box
                  key={index}
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography
                    fontSize={"12px"}
                    fontWeight={700}
                    color={ColorScheme.grey}
                  >
                    {calculateDuration(reservation)}
                  </Typography>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {originalPrices.reservations[index].vat_price !==
                    reservation.vat_price ? (
                      <Typography
                        sx={{
                          color: ColorScheme.lightGrey,
                          fontSize: "14px",
                          fontStyle: "italic",
                          fontWeight: "700",
                          textDecoration: "line-through",
                        }}
                      >
                        {originalPrices.reservations[index].vat_price} €
                      </Typography>
                    ) : (
                      <div />
                    )}
                    <Typography
                      fontSize={"14px"}
                      fontWeight={700}
                      color={ColorScheme.grey}
                    >
                      {reservation.vat_price} €
                    </Typography>
                  </div>
                </Box>
              );
            })}
          </Box>
        )}
        {discountedProducts.length > 0 && (
          <Box style={{ marginTop: "30px" }}>
            <Typography style={{ fontSize: "16px", fontWeight: "700" }}>
              {"Tuotteet"}
            </Typography>
            {discountedProducts.map((product, index) => {
              return (
                <Box
                  key={index}
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography
                    fontSize={"12px"}
                    fontWeight={700}
                    color={ColorScheme.grey}
                  >
                    {product.name}
                  </Typography>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {originalPrices.products[index].vat_price !==
                    product.vat_price ? (
                      <Typography
                        sx={{
                          color: ColorScheme.lightGrey,
                          fontSize: "14px",
                          fontStyle: "italic",
                          fontWeight: "700",
                          textDecoration: "line-through",
                        }}
                      >
                        {originalPrices.products[index].vat_price} €
                      </Typography>
                    ) : (
                      <div />
                    )}
                    <Typography
                      fontSize={"14px"}
                      fontWeight={700}
                      color={ColorScheme.grey}
                    >
                      {product.vat_price} €
                    </Typography>
                  </div>
                </Box>
              );
            })}
          </Box>
        )}

        <Box
          sx={{
            my: "20px",
            height: 2,
            width: "100%",
            backgroundColor: ColorScheme.lightGrey,
          }}
        />
        <Box>
          <Box
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography fontSize={"16px"} fontWeight={700} color={"black"}>
              {"Yhteensä"}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={700} color={"black"}>
              {getTotalPrice().toFixed(2)} €
            </Typography>
          </Box>
        </Box>
      </Box>

      {!isLogged && (
        <Box
          sx={{
            width: "100%",
            marginTop: "30px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              marginBottom: "5px",
            }}
          >
            Syötä sähköpostiosoitteesi
          </Typography>

          <TextField
            error={
              (orderEmail !== "" &&
                !isEmailValid(orderEmail) &&
                !invalidEmail) ||
              invalidEmail
            }
            size="medium"
            /*  helperText={
              // find a way to make helperText background transparent so it wont mess with parent box shadow
              invalidEmail ? "Virheellinen sähköpostiosoite" : undefined
            } */
            FormHelperTextProps={{
              style: {
                backgroundColor: "transparent",
              },
            }}
            placeholder="Sähköpostiosoite..."
            fullWidth
            onChange={(text) => setOrderEmail(text.target.value)}
            sx={{
              boxShadow: " 0px 0px 30px 2px rgba(0,0,0,0.07);",
              fieldset: {
                borderColor: "#D9D9D9",
              },
            }}
            inputProps={{
              style: {
                backgroundColor: ColorScheme.veryLightGrey,
                borderColor: "#D9D9D9",
                color: "#222222",
                fontWeight: "700",
              },
            }}
          />
        </Box>
      )}

      <Box sx={{ width: "100%", marginTop: "30px" }}>
        <Typography sx={{ fontWeight: "700", marginBottom: "5px" }}>
          Onko sinulla kampanjakoodi?
        </Typography>
        <Box style={{ flexDirection: "row", display: "flex" }}>
          <TextField
            disabled={codeActivated}
            size="medium"
            placeholder="Kampanjakoodi..."
            error={invalidCode}
            sx={{
              boxShadow: " 0px 0px 30px 2px rgba(0,0,0,0.07);",
              fieldset: {
                borderColor: "#D9D9D9",
              },
            }}
            helperText={
              invalidCode ? (
                <Typography color="error" fontSize="small" position="absolute">
                  {errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1)}
                </Typography>
              ) : undefined
            }
            fullWidth
            value={
              campaignCode +
              (codeActivated
                ? " - " +
                  getDiscountLabel(
                    discountType.discount_euros === 0
                      ? discountType.discount_type
                      : 0
                  ) +
                  "-" +
                  (discountType.discount_euros === 0
                    ? discountType.discount_percent + "%"
                    : discountType.discount_euros + "€")
                : "")
            }
            onChange={(text) => {
              setCampaignCode(text.target.value);
              if (invalidCode) setInvalidCode(false);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter" && campaignCode !== "") {
                handleCodeCheck();
              }
            }}
            inputProps={{
              style: {
                backgroundColor: ColorScheme.veryLightGrey,
                color: "#222222",
                fontWeight: "700",
              },
            }}
          />
          <Box
            onClick={() =>
              codeActivated
                ? resetCode()
                : campaignCode != "" && handleCodeCheck()
            }
            disabled={campaignCode === ""}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "25%",
              height: "54px",
              border:
                "1px solid" +
                (campaignCode === ""
                  ? ColorScheme.veryLightGrey
                  : codeActivated
                  ? ColorScheme.red
                  : ColorScheme.tolotechBlue),
              borderRadius: "4px",
              backgroundColor:
                campaignCode === ""
                  ? ColorScheme.veryLightGrey
                  : codeActivated
                  ? ColorScheme.red
                  : ColorScheme.tolotechBlue,
              color: campaignCode === "" ? ColorScheme.lightGrey : "white",
              marginLeft: "10px",
            }}
          >
            <Typography sx={{ textAlign: "center" }}>
              {codeActivated ? "Poista" : "Aktivoi"}
            </Typography>
          </Box>
        </Box>

        <div style={{ height: "50px" }} />

        {balance !== null && balance !== 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
              /* justifyContent: "flex-start", */
            }}
            onClick={() =>
              balance === 0 ? undefined : setUseBalance(!useBalance)
            }
          >
            <Checkbox
              checked={useBalance}
              disabled={balance === 0}
              sx={{
                color: ColorScheme.tolotechBlue,
                "&.Mui-checked": {
                  color: ColorScheme.tolotechBlue,
                },
              }}
            />
            <Typography
              color="black"
              fontSize="14px"
              sx={{ userSelect: "none" }}
            >
              Käytä saldoa ({balance} €)
            </Typography>
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            /* marginTop: "50px", */
            width: "100%",
            justifyContent: "flex-start",
            gap: "4px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            onClick={() => setTermsAccepted(!termsAccepted)}
          >
            <Checkbox
              checked={termsAccepted}
              sx={{
                color: ColorScheme.tolotechBlue,
                "&.Mui-checked": {
                  color: ColorScheme.tolotechBlue,
                },
              }}
            />
            <Typography color="black" fontSize="14px">
              Olen lukenut ja hyväksyn
            </Typography>
          </div>
          <Link
            variant="text"
            href={BASE_URL + "public/termsofservice.pdf"}
            target="_blank"
            sx={{ cursor: "pointer" }}
          >
            <Typography
              fontSize="14px"
              style={{ textDecorationLine: "underline" }}
            >
              toimitusehdot.
            </Typography>
          </Link>
        </div>

        <Collapse in={showError !== ""}>
          <Alert severity="error" sx={{ marginTop: "20px" }}>
            {showError}
          </Alert>
        </Collapse>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            paddingTop: "20px",
          }}
        >
          <Box
            onClick={() => returnBack()}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignSelf: "center",
              alignContent: "center",
              justifyContent: "center",
              /* width: "140px", */
              width: "100%",
              height: "25px",
              /* padding: "10px", */
              py: "10px",
              borderRadius: "8px",
              fontSize: "18px",
              backgroundColor: ColorScheme.tolotechBlue,
              border: "2px solid " + ColorScheme.tolotechWhite,
              color: ColorScheme.tolotechWhite,
              transition: ".3s all",
              "&:hover": {
                cursor: "pointer",
                transform: {
                  scale: 1.1,
                },
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              Peruuta
            </Typography>
          </Box>

          <Box
            onClick={() =>
              (isLogged || isEmailValid(orderEmail)) &&
              termsAccepted &&
              !loadingNewOrder &&
              handleSubmit()
            }
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignSelf: "center",
              alignContent: "center",
              justifyContent: "center",
              /* width: "140px", */
              width: "100%",
              height: "25px",
              /* padding: "10px", */
              py: "10px",
              borderRadius: "8px",
              fontSize: "18px",
              backgroundColor: ColorScheme.tolotechBlue,
              opacity:
                (isLogged || isEmailValid(orderEmail)) && termsAccepted
                  ? 1
                  : 0.5,
              border: "2px solid " + ColorScheme.tolotechWhite,
              color: ColorScheme.tolotechWhite,
              transition: ".3s all",
              "&:hover": {
                cursor: "pointer",
                transform: {
                  scale: 1.1,
                },
              },
            }}
          >
            {!loadingNewOrder ? (
              <Typography
                sx={{
                  fontSize: "16px",
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                Jatka maksamaan
              </Typography>
            ) : (
              <CircularProgress />
            )}
          </Box>
        </div>
      </Box>
    </Box>
  );
};
