import React from "react";
import TolotechLogo from "../../Assets/Tolotech_logo_new.png";
import { GDPRBanner } from "../Misc/GDPRBanner";

export const Footer = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        paddingBottom: "20px",
        paddingTop: "20px",
        textAlign: "center",
        fontSize: "8px",
        alignItems: "center",
        position: "relative",
        bottom: 0,
        transition: "all 1s ease-in-out",
      }}
    >
      <img
        alt=""
        style={{
          height: "auto",
          maxWidth: "150px",
          objectFit: "cover",
        }}
        src={TolotechLogo}
      />
      Copyright © {new Date().getFullYear()}
      <GDPRBanner />
    </div>
  );
};
