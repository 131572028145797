export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "https://paivitystesti.paas.datacenter.fi/"
    : "https://tologo-master-production.paas.datacenter.fi/";

export const isMobile = window.innerWidth <= 768;

export const HOST =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://booking.tologo.fi";
