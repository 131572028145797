import { CircularProgress, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { ColorScheme } from "../../Utils/ColorScheme";
import SVG from "react-inlinesvg";
import { isDesktop } from "react-device-detect";
import { useHorizontalScroll } from "../../Utils/useHorizontalScroll";
import { useLocation } from "react-router-dom";

function ClassItem({ serviceClass, handleSelect, selected, isFirst, isLast }) {
  const itemRef = useRef(null);
  const [iconSvg, setIconSvg] = useState("");
  const [isHovered, setHovered] = useState(false);

  function modifySvg(svgString, stroke, strokeWidth) {
    const styleString = `stroke="${stroke}" stroke-width="${strokeWidth}"`;

    let newString = svgString.replace("<svg", `<svg ${styleString}`);
    return newString;
  }

  const handleClick = () => {
    handleSelected();
    setHovered(false);
  };

  useEffect(() => {
    let svg = modifySvg(
      serviceClass.image,
      selected ? ColorScheme.tolotechWhite : ColorScheme.bg,
      "1.5"
    );

    setIconSvg(svg);
  }, [selected, serviceClass.image]);

  const handleSelected = () => {
    handleSelect(serviceClass);
  };

  useEffect(() => {
    if (selected && itemRef.current) {
      itemRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [selected]);

  return (
    <Box
      ref={itemRef}
      onClick={() => (selected ? undefined : handleClick())}
      /* Prevent hover effect moving around if user is mouse dragging class selector */
      onMouseOver={(event) => !selected && !event.buttons && setHovered(true)}
      onMouseLeave={(event) => !selected && !event.buttons && setHovered(false)}
      sx={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        minWidth: "100px",
        /*  maxWidth: "calc(100% - 2px)", */
        height: "calc(100% - 2px)",
        flexDirection: "column",
        /* position: "relative", */
        borderRadius: isFirst
          ? "4px 0px 0px 4px"
          : isLast
          ? "0px 4px 4px 0px"
          : "0px",
        background: selected
          ? "linear-gradient(rgba(38,65,102,0.9), rgba(38,65,102,1))"
          : ColorScheme.veryLightGrey,

        // borderTop: !selected && "1px solid" + ColorScheme.lightGrey,
        /* borderBottom: !selected
          ? "1px solid" + ColorScheme.lightGrey
          : "2px solid" + ColorScheme.tolotechDarkBlue, */

        borderTop: selected
          ? "1px solid" + ColorScheme.tolotechDarkBlue
          : "1px solid" + ColorScheme.lightGrey,
        borderBottom: selected
          ? "1px solid" + ColorScheme.tolotechDarkBlue
          : "1px solid" + ColorScheme.lightGrey,
        borderLeft: isFirst
          ? "1px solid " +
            (selected ? ColorScheme.tolotechDarkBlue : "transparent")
          : "none",
        borderRight: selected
          ? "1px solid" + ColorScheme.tolotechDarkBlue
          : isLast
          ? "1px solid transparent"
          : "1px solid" + ColorScheme.lightGrey,
        margin: "0px",
        ...(isDesktop && {
          "&:hover": {
            //fontWeight: "800",
            //textShadow: ColorScheme.black + "2px 0 12px",
            /* borderTop: !selected
              ? "1px solid" + ColorScheme.select_green
              : "1px solid transparent", */
            /* cursor: "pointer", */
            //color: "white",
          },
        }),
      }}
    >
      <Box
        sx={{
          display: "flex",
          height: "1px",
          width: "100%",
          backgroundColor: isHovered
            ? ColorScheme.select_green
            : !selected
            ? undefined /* ColorScheme.lightGrey */
            : ColorScheme.tolotechDarkBlue,
        }}
      />
      <Box
        sx={{
          display: isDesktop ? "flex" : "none",
          height: "1px",
          width: "100%",
          backgroundColor: isHovered ? ColorScheme.select_green : "transparent",
        }}
      />
      <div style={{ flex: 1 }} />
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <SVG
          src={iconSvg}
          width={20}
          height={20}
          title="class"
          stroke={selected ? "white" : ColorScheme.tolotechDarkBlue}
        />
        <Typography
          sx={{
            fontSize: "12px",
            marginLeft: "10px",
            color: selected ? "white" : ColorScheme.tolotechDarkBlue,
            fontWeight: "600",
          }}
        >
          {serviceClass.name_fi}
        </Typography>
      </Box>
      <div
        style={{
          flex: 1,
        }}
      />
      {/*  <div
        style={{
          height: "1px",
          width: "100%",
          backgroundColor: selected
            ? ColorScheme.tolotechDarkBlue
            : ColorScheme.lightGrey,
        }}
      /> */}
    </Box>
  );
}

function ClassSelector({
  location,
  setSelectedClass,
  selectedClass,
  skipPhase,
}) {
  const [locationClasses, setLocationClasses] = useState([]);
  const [nothingHere, setNothingHere] = useState(false);
  const [hideContent, setHideContent] = useState(false); // state for temporal fix for locations with no service_classes from backend, should be fixed on server side at some point

  const scrollRef = useHorizontalScroll();

  // Access route parameters
  const locationURL = useLocation();

  // const widthCheck = useRef();

  /* const checkOverflow = (event) => {
    if (event === undefined || event === null) return;
    return event.offsetWidth < event.scrollWidth;
  }; */

  const checkParameter = () => {
    // Get query parameters
    const queryParams = new URLSearchParams(locationURL.search);
    // Get class_id from url
    const class_id = queryParams.get("class");

    if (class_id === null || class_id === undefined) return selectedClass?.id; // if no class_id in url, return current selected class instead of 0 resetting view to first available class when going back
    else return Number(class_id);
  };

  useEffect(() => {
    if (!location) return;

    const class_id = checkParameter();

    if (class_id !== undefined && class_id !== null) {
      setLocationClasses(location.service_classes);
      setSelectedClass(
        location.service_classes && location.service_classes.length !== 0 // if location has no service classes, return empty array
          ? location.service_classes.find((c) => c.id === class_id) ||
              location.service_classes[0]
          : []
      ); // set default class from url parameter or first class if not found (incorrect class id)

      /*  if (location.service_classes && location.service_classes.length === 0) {
        if (location.has_products) {
        setHideContent(true);
      } else setNothingHere(true);
    } */
      return;
    }

    if (
      selectedClass !== null &&
      selectedClass !== undefined &&
      selectedClass.id !== undefined
    ) {
      // if user is going back from checkout this prevents the class selector from resetting back to first class
      setLocationClasses(location.service_classes);
      setSelectedClass(selectedClass);
      return;
    }

    if (
      locationClasses.length == 0 &&
      location != null &&
      location.service_classes != null &&
      location.service_classes.length > 0
    ) {
      setLocationClasses(location.service_classes);
      setSelectedClass(location.service_classes[0]);
    } else if (
      location != null &&
      location.service_classes != null &&
      location.service_classes.length === 0
    )
      if (location.has_products && !location.has_reservations) {
        skipPhase(1); // temporary fix for locations with no service_classes from backend, should be fixed on server side at some point. Added number 1 to skipPhase to skip to product selection
        setHideContent(true);
      } else {
        setHideContent(false);
        setNothingHere(true);
      }
  }, [location]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        borderRadius: "6px",
        overflow: "hidden",
        borderRight:
          locationClasses &&
          locationClasses.length > 0 &&
          selectedClass !== null &&
          "1px solid" + ColorScheme.lightGrey,
        borderLeft:
          locationClasses &&
          locationClasses.length > 0 &&
          selectedClass !== null &&
          "1px solid" + ColorScheme.lightGrey,
      }}
    >
      <Box
        /*  ref={(node) => {
          // enables multiple refs in single element
          widthCheck.current = node;
          scrollRef.current = node;
        }} */
        ref={scrollRef}
        sx={{
          flexDirection: "row",
          display: "flex",
          height: "50px",
          width: "100%",
          // border: selectedClass !== null && "1px solid transparent",
          // borderRadius: "6px",
          // overflowX: "auto",
          flexWrap: "nowrap",
          whiteSpace: "nowrap",
          /* filter: "opacity(100%)", */
          position: "relative",
          justifyContent: selectedClass == null && "center",
          alignItems: selectedClass == null && "center",
          overflowX: "scroll",
          cursor: "pointer",
          userSelect: "none",
          touchAction: "pan-y",
        }}
        /* Something seriously broke sideways dragging so this little bit will atleast bring that functionality back and enable mouse dragging on desktop too which wasn't possible before */

        onMouseDown={(e) => {
          e.preventDefault();
          const startX = e.pageX - scrollRef.current.offsetLeft;
          const scrollLeft = scrollRef.current.scrollLeft;

          const handleMouseMove = (e) => {
            const x = e.pageX - scrollRef.current.offsetLeft;
            const walk = (x - startX) * 2; // Adjust the scroll speed here
            scrollRef.current.scrollLeft = scrollLeft - walk;
          };

          document.addEventListener("mousemove", handleMouseMove);
          document.addEventListener("mouseup", () => {
            document.removeEventListener("mousemove", handleMouseMove);
          });
        }}
        
        /* And same for touch devices is handled via useHorizontalScroll.js */
      >
        {hideContent ? undefined : nothingHere ? (
          <Typography fontSize="14px" fontWeight="600">
            Tässä kohteessa ei ole vielä varattavia/ostettavia tuotteita!
          </Typography>
        ) : (
          selectedClass == null && <CircularProgress />
        )}
        {locationClasses != null &&
          locationClasses.length > 0 &&
          locationClasses.map((serviceClass, i) => {
            /* if (serviceClass.type === 2) return null; // dont return broken and long term reservable classes to web booking */
            return (
              <ClassItem
                key={serviceClass.name_fi}
                isFirst={i === 0}
                handleSelect={setSelectedClass}
                selected={
                  selectedClass !== null && selectedClass.id === serviceClass.id
                }
                serviceClass={serviceClass}
                isLast={i === locationClasses.length - 1}
                isNext={
                  selectedClass !== null &&
                  i ===
                    locationClasses.findIndex(
                      (c) => c.id === selectedClass.id
                    ) +
                      1
                }
              />
            );
          })}
      </Box>
    </Box>
  );
}

export default ClassSelector;
