import { Box, Typography } from "@mui/material";
import React from "react";
/* import { parseTimestamp } from "../../Utils/parseTimestamp"; */
import { ReactComponent as ArrowIcon } from "../../Assets/icon-arrowsmallright.svg";
import { ReactComponent as DoubleArrowIcon } from "../../Assets/DoubleArrow.svg";
import { ColorScheme } from "../../Utils/ColorScheme";
import { format } from "date-fns";
import { isDesktop } from "react-device-detect";

export const LocationBalanceSmall = ({ data }) => {
  /* const checkStatus = (value) => {
    if (data.amount === 0) return "";
    if (data.amount > 0) return parseTimestamp(value);
  }; */

  const checkValue = (value) => {
    if (Number.isInteger(value)) {
      return value.toLocaleString("fi-FI");
    } else {
      return value.toFixed(2).replace(".", ",");
    }
  };

  const latestTransactions = data?.transactions?.slice(0, 5); // Show only 5 latest transactions
  const logo = data?.location?.logo_image?.image;
  const locationBalance = checkValue(data?.amount);
  const expireDate = new Date(data?.expires_at).toLocaleDateString("fi-FI");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "20px",
        backgroundColor: ColorScheme.white,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "calc(100% / 3)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: isDesktop ? "20px" : "10px",
          }}
        >
          <img
            src={logo}
            alt={data?.location?.name}
            style={{
              width: "35px",
              height: "35px",
              borderRadius: "4px",
              objectFit: "cover",
            }}
          />
          <Typography fontWeight={700} fontSize={"18px"}>
            {data?.location?.name}
          </Typography>
        </div>
        {/* <div style={{ width: "calc(100% / 3)", textAlign: "center" }}>
        <Typography>{checkStatus(data?.expires_at)}</Typography>
      </div> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              /*  width: "calc(100% / 3 + 40px)", */
              textAlign: "right",
              paddingRight: "20px",
            }}
          >
            <Typography
              fontWeight={700}
              fontSize={"24px"}
              sx={{ marginBottom: "-5px" }}
            >
              {locationBalance} €
            </Typography>
            <Typography
              fontWeight={700}
              fontSize={"12px"}
              color={ColorScheme.blackOpacity}
            >
              Voimassa {expireDate} asti
            </Typography>
          </div>
          <div style={{ rotate: "90deg" }}>
            <DoubleArrowIcon height={20} width={20} stroke={ColorScheme.black}/>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: ColorScheme.veryLightGrey,
          borderRadius: "4px",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        {latestTransactions.map((transaction, i) => {
          return (
            <div
              key={i}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "20px",
              }}
            >
              <Typography fontSize={"12px"} fontWeight={700}>
                {format(new Date(transaction?.created_at), "dd.MM.yyyy")}
              </Typography>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                <Typography fontSize={"14px"} fontWeight={700}>
                  {transaction?.amount > 0 ? `+` : ""}
                  {checkValue(transaction?.amount)} €
                </Typography>

                <div
                  style={{
                    rotate: transaction?.amount > 0 ? "270deg" : "90deg",
                  }}
                >
                  <ArrowIcon
                    height={10}
                    width={10}
                    stroke={transaction?.amount > 0 ? ColorScheme.darkGreen : ColorScheme.red}
                    strokeWidth={15}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Box>
  );
};
