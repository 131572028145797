import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../Utils/ApiContext";
import { OrderCard } from "../Orders/OrderCard";
import { LoadMoreButton } from "./LoadMoreButton";

export const UserOrders = ({ allLocations }) => {
  const { ApiCall } = useContext(ApiContext);
  const [orders, setOrders] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);

  const [loadMore, setLoadMore] = useState(false);

  /* const handleChange = (values) => {
    setIsLoading(true);
    setPageIndex(values);
  }; */

  const fetchOrders = (page) => {
    setIsLoading(true);
    ApiCall("GET", `user/order/history?page=${page || 1}`, null)
      .then((res) => {
        if (res.length > 9) setLoadMore(true);
        else setLoadMore(false);

        if (pageIndex > 1 && res.length === 0) {
          setPageIndex(pageIndex - 1);
        } else {
          setPageIndex(page || 1);
          setOrders((prevOrders) => [...prevOrders, ...res]);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  const handleLoadMore = (value) => {
    fetchOrders(value);
  };

  useEffect(() => {
    setOrders([]);
    fetchOrders();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        borderRadius: "4px",
        /* border: "1px solid #D9D9D9", */
        transition: "all 0.3s ease",
        overflow: "hidden",
      }}
    >
      {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <div style={{ width: "35%", fontSize: "12px", fontWeight: "600" }}>
            Paikka
          </div>
          <div style={{ flex: 1 }} />
          <div
            style={{
              width: "35%",
              fontSize: "12px",
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            Ostoaika
          </div>
          <div style={{ flex: 1 }} />
          <div
            style={{
              width: "25%",
              fontSize: "12px",
              textAlign: "right",
              fontWeight: "600",
            }}
          >
            Hinta / Tila
          </div>
        </div> */}

      {isLoading && orders.length === 0 ? (
        <div
          style={{
            padding: "40px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "40px",
          }}
        >
          <CircularProgress />
          <Typography>Ladataan ostotietoja ...</Typography>
        </div>
      ) : orders.length > 0 ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Box
            sx={{
              border: "1px solid #D9D9D9",
              borderRadius: "4px",
              overflow: "hidden",
              transition: "all 0.3s ease",
            }}
          >
            {orders.map((entry, i) => {
              const date = new Date(entry.created_at);

              return (
                <OrderCard
                  key={i}
                  order={entry}
                  index={i}
                  date={date}
                  locations={allLocations}
                />
              );
            })}
          </Box>

          <LoadMoreButton
            {...{ handleLoadMore, pageIndex, loadMore, isLoading }}
          />
        </Box>
      ) : (
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            textAlign: "center",
            marginTop: "30%",
          }}
        >
          Yhtään tilausta ei ole vielä tehty tällä tilillä.
        </div>
      )}
    </div>
  );
};
