import {
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../Utils/ApiContext";
import { ButtonBase } from "./ButtonBase";
import { ColorScheme } from "../../Utils/ColorScheme";
import "../../Styles/Transitions.css";
import { getCreatedDateDaysAgo } from "../../Utils/getCreatedDateDaysAgo";
import { parseMessage } from "../../Utils/parseMessage";

export const Logout = ({ data, handleLogout, handleRedirect, passLogin }) => {
  const { ApiCall } = useContext(ApiContext);

  const [passPhrase, setPassPhrase] = useState("");
  const [passRepeat, setPassRepeat] = useState("");
  const [username, setUsername] = useState("");
  const [userID, setUserID] = useState("");
  const [firstLogin, setFirstLogin] = useState(false);

  const [notifications, setNotifications] = useState([]);
  const [showLoader, setShowLoader] = useState(true);

  const limitNotifications = 3; // Limit displayed notifications to certain number

  const getNotifications = () => {
    ApiCall("GET", "user/notifications?page=1", null)
      .then((res) => {
        setNotifications(res.slice(0, limitNotifications));
        if (res.length === 0) setShowLoader(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUserData = () => {
    ApiCall("GET", "user", null)
      .then((event) => {
        setUserID(event.user_id); // store user_id for later use
      })
      .then(() => getNotifications())
      .catch((error) => console.log(error));
  };

  const saveUserPass = () => {
    ApiCall("PUT", `user/${userID}`, {
      name: username,
      password: passPhrase,
    }).then(() => {
      setFirstLogin(false);
      passLogin(true);
    });
  };

  useEffect(() => {
    if (data?.name === "") setFirstLogin(true); // show username and password fields for first login session
    getUserData();
  }, [data]);

  /* passPhrase !== passRepeat || passPhrase.length < 4 */

  return firstLogin ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "20px",
        gap: "10px",
      }}
    >
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: "900",
          py: "5px",
          marginBottom: "20px",
        }}
        color="black"
      >
        Määritä tilillesi käyttäjänimi ja uusi salasana
      </Typography>

      <TextField
        label="Nimi"
        id="username"
        type="text"
        fullWidth
        value={username}
        onChange={(value) => setUsername(value.target.value)}
        /* sx={{ marginBottom: "5px", py: "5px" }} */
        InputProps={{
          inputProps: {
            style: {
              textAlign: "left",
              justifyContent: "center",
            },
          },
        }}
      />
      <TextField
        label="Kirjoita salasana"
        id="1stpass"
        type="password"
        fullWidth
        value={passPhrase}
        onChange={(value) => setPassPhrase(value.target.value)}
        /* sx={{ marginBottom: "5px", py: "5px" }} */
        InputProps={{
          inputProps: {
            style: {
              textAlign: "left",
              justifyContent: "center",
            },
          },
        }}
      />
      <TextField
        label="Toista salasana"
        id="2ndpass"
        type="password"
        fullWidth
        onChange={(value) => {
          setPassRepeat(value.target.value);
        }}
        InputProps={{
          inputProps: {
            style: {
              textAlign: "left",
              justifyContent: "center",
            },
          },
        }}
      />

      <div style={{ flex: 1 }} />

      <Button
        type="submit"
        variant="contained"
        onClick={() => saveUserPass()}
        disabled={passPhrase !== passRepeat || passPhrase.length < 4}
        inputProps={{
          style: { textAlign: "center" },
          disableUnderline: true,
        }}
        sx={{ width: "100%", height: "44px", marginTop: "20px" }}
      >
        Lähetä
      </Button>
    </div>
  ) : (
    <Stack
      alignItems="center"
      gap="10px"
      sx={{
        transition: "max-height 1s ease-in-out",
        /*  maxHeight: "500px", */
        overflow: "hidden",
      }}
    >
      <div
        style={{ width: "100%" /* paddingTop: "5px", paddingLeft: "20px" */ }}
      >
        <Typography fontWeight="700" color="#323232">
          {data?.name || username}
        </Typography>
        <Typography
          fontWeight="700"
          fontSize="12px"
          color="#505050"
          lineHeight="0.9"
        >
          {data?.email}
        </Typography>
      </div>

      <div
        style={{
          flex: 1,
          /*  border: "1px solid" + ColorScheme.lightGrey, */
          borderRadius: "4px",
          /*  padding: "15px", */
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
        }}
      >
        {notifications.length === 0 ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              paddingTop: "25px",
            }}
          >
            <CircularProgress
              size={18}
              animation={showLoader ? "pulse" : false}
            />
          </div>
        ) : (
          <>
            {notifications.map((item, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderTop:
                      index !== 0 && "1px solid" + ColorScheme.lightGrey,
                    padding: "4px",
                    opacity: 0,
                    animation: "fadeIn 0.5s ease-in forwards",
                  }}
                  key={index}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography fontSize={14} fontWeight={700}>
                      {item.location.name}
                    </Typography>
                    <Typography fontSize={10}>
                      {getCreatedDateDaysAgo(item.created_at)}
                    </Typography>
                  </div>
                  <Typography fontSize={12}>
                    {parseMessage(item.message)}
                  </Typography>
                </div>
              );
            })}

            <div
              style={{ display: "flex", width: "100%", justifyContent: "end" }}
            >
              <Button
                sx={{
                  textTransform: "capitalize",
                  minWidth: "0px"
                }}
                size={"small"}
                onClick={() => handleRedirect("/notifications")}
              >
                Lisää
              </Button>
            </div>
          </>
        )}
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "50px",
          boxSizing: "border-box",
          /*  paddingLeft: "20px",
          paddingRight: "20px", */
          gap: "10px",
        }}
      >
        <ButtonBase handleClick={handleRedirect} text={"Omat tiedot"} redirectTo={"/user"} />
        <ButtonBase handleClick={handleLogout} text={"Kirjaudu ulos"} />
      </div>
    </Stack>
  );
};
