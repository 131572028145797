export function parseMessage(message) {

    // Split the message into words
    const words = message.split(" ");

    // Map through the words, turning any word that matches the URL pattern into a link
    return words
        .map((word, index) => {
            const urlPattern =
                /(https?:\/\/[^\s]+)|(www\.[^\s]+)|((?:[a-z]+\.)?[a-z0-9]+\.[a-z]+(?:\.[a-z]+)?)|(https?:\/\/[^\s]+:\d+)|(https?:\/\/[^\s]+\/[^\s]*)|([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;

            const match = urlPattern.exec(word);
            if (match) {
                return (
                    <a
                        key={index}
                        href={
                            match[0].startsWith("http") ? match[0] : `http://${match[0]}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {match[0]}
                    </a>
                );
            } else {
                return word;
            }
        })
        .reduce(
            (prev, current, index) =>
                // Add a space after each word, except the last one
                index < words.length - 1
                    ? [...prev, current, " "]
                    : [...prev, current],
            []
        );
}