import { Button, CircularProgress, Typography } from "@mui/material";
import React from "react";

export const LoadMoreButton = ({
  handleLoadMore,
  pageIndex,
  loadMore,
  isLoading,
}) => {
  return (
    <Button
      onClick={() => handleLoadMore(pageIndex + 1)}
      disabled={!loadMore}
      variant="contained"
      sx={{
        display: !loadMore && "none",
        width: "100%",
        height: "50px",
      }} // overrides theme file overrides
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Typography fontSize="20px" fontWeight="600">
          Lataa lisää
        </Typography>
      )}
    </Button>
  );
};
