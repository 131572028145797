import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../Utils/ApiContext";
import { LocationBalance } from "./LocationBalance";

export const UserBalance = () => {
  const { ApiCall } = useContext(ApiContext);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchBalanceData = () => {
    setIsLoading(true);

    ApiCall("GET", "user/balances")
      .then((res) => {
        // console.log(res);
        setData(res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
 
  useEffect(() => {
    fetchBalanceData();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "4px",
        transition: "all 0.3s ease",
        overflow: "hidden",
        // gap: "20px",
        /* backgroundColor: "#fafafa", */
      }}
    >
      {isLoading ? (
        <div
          style={{
            padding: "40px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "40px",
          }}
        >
          <CircularProgress />
          <Typography>Ladataan saldotietoja ...</Typography>
        </div>
      ) : data.length === 0 ? (
        <div
          style={{
            display: "flex",
            padding: "40px",
            justifyContent: "center",
          }}
        >
          <Typography>Ei käytettäviä saldoja</Typography>
        </div>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Box
            sx={{
              border: "1px solid #D9D9D9",
              borderRadius: "4px",
              overflow: "hidden",
              transition: "all 0.3s ease",
            }}
          >
            {data.map((location, i) => {
              return (
                <LocationBalance
                  key={i}
                  data={location}
                  isFirst={i === 0}
                  amount={data.length}
                />
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};
