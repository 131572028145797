import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../Utils/ApiContext";
import { getCreatedDateDaysAgo } from "../../Utils/getCreatedDateDaysAgo";
import { ColorScheme } from "../../Utils/ColorScheme";
import { parseMessage } from "../../Utils/parseMessage";
import { LoadMoreButton } from "./LoadMoreButton";

export const UserNotifications = () => {
    const { ApiCall } = useContext(ApiContext);
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pageIndex, setPageIndex] = useState(1);
    const [loadMore, setLoadMore] = useState(false);

    const getNotifications = (page) => {
        setIsLoading(true);

        ApiCall("GET", `user/notifications?page=${page || 1}`, null)
            .then((res) => {

                if (res.length > 19) setLoadMore(true);
                else setLoadMore(false);

                if (pageIndex > 1 && res.length === 0) {
                    setPageIndex(pageIndex - 1);
                } else {
                    setPageIndex(page || 1);
                    setNotifications((prevNotifs) => [...prevNotifs, ...res]);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => setIsLoading(false));
    };

    const handleLoadMore = (value) => {
        getNotifications(value);
    };

    useEffect(() => {
        getNotifications();
    }, []);

    return (
        <div
            style={{
                minHeight: "200px",
                display: "flex",
                flexDirection: "column",
                borderRadius: "4px",
                /* border: "1px solid #D9D9D9", */
                transition: "all 0.3s ease",
                overflow: "hidden",
                /* backgroundColor: "#fafafa", */
                // gap: "20px",
            }}
        >
            {isLoading && notifications.length === 0 ? (
                <div
                    style={{
                        padding: "40px",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "40px",
                    }}
                >
                    <CircularProgress />
                    <Typography>Ladataan ilmoituksia ...</Typography>
                </div>
            ) : notifications.length > 0 ? (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ padding: "20px" }}>
                        <Typography fontSize={24} fontWeight={700}>
                            Ilmoitukset
                        </Typography>
                    </div>

                    <Box
                        sx={{
                            overflow: "hidden",
                        }}
                    >
                        {notifications.map((entry, i) => {
                            return (
                                <div
                                    key={i}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        border: "1px solid #D9D9D9",
                                        borderRadius: "4px",
                                        padding: "15px",
                                        margin: "15px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "inherit",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            borderBottom: "1px solid" + ColorScheme.veryLightGrey,
                                        }}
                                    >
                                        <Typography fontSize={14} fontWeight={700}>
                                            {entry.title}
                                        </Typography>

                                        <Typography fontSize={10}>
                                            {getCreatedDateDaysAgo(entry.created_at)}
                                        </Typography>
                                    </div>

                                    <div
                                        style={{
                                            padding: "5px",
                                            paddingTop: "15px",
                                        }}
                                    >
                                        <Typography fontSize={12}>
                                            {entry.location?.name !== entry.title &&
                                                entry.location?.name}
                                        </Typography>
                                        <Typography fontSize={12}>
                                            {parseMessage(entry.message)}
                                        </Typography>
                                    </div>
                                </div>
                            );
                        })}
                    </Box>

                    <div style={{ padding: "15px" }}>
                        <LoadMoreButton
                            {...{ handleLoadMore, pageIndex, loadMore, isLoading }}
                        />
                    </div>
                </Box>
            ) : (
                <div
                    style={{
                        padding: "40px",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "40px",
                    }}
                >
                    <Typography textAlign="center">
                        Yhtään tilausta ei ole vielä tehty tällä tilillä.
                    </Typography>
                </div>
            )}
        </div>
    );
};
