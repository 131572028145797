import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Router } from "../../Utils/Router";
import { ApiContext } from "../../Utils/ApiContext";
import LocationInfo from "../Topbar/LocationInfo";

//  import { UserContext } from "../../Utils/UserContext";

import { Footer } from "./Footer";
import { GetScreenWidth } from "../../Utils/GetScreenWidth";

const MainView = () => {
  const { ApiCall } = useContext(ApiContext);
  // const { isLogged, setIsLogged } = useContext(UserContext);
  const navigate = useNavigate();

  const debug = false;

  const [location, setLocation] = useState(null);
  const [loadingState, setLoadingState] = useState(false);

  const locationPath = useLocation();

  const slicedPath =
    locationPath.pathname === "/success" || locationPath.pathname === "/cancel"
      ? locationPath.search.slice(5) // exclude first 5 characters ie. ?lid=
      : locationPath.pathname.slice(locationPath.pathname.lastIndexOf("/") + 1);

  if (
    isNaN(slicedPath) &&
    locationPath.pathname !== "/user" &&
    locationPath.pathname !== "/notifications" &&
    locationPath.pathname !== "/"
  )
    navigate("/"); // catch invalid attempts and return to frontpage

  const handleLogin = (prop) => {
    if (prop) {
      fetchApiData();
    }
  };

  // Fixed logout function to get cookie cleared properly without refreshing the page

  const removeCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
  };

  const handleLogout = () => {
    // Create a Promise that resolves after the cookie is removed
    const removeCookiePromise = new Promise((resolve) => {
      removeCookie("token-booking");
      resolve();
    });

    // Wait for the Promise to resolve and then navigate
    removeCookiePromise.then(() => {
      navigate("/");
    });
  };

  const changeLoadingState = (value) => {
    setLoadingState(value);
  };

  const fetchApiData = () => {
    if (isNaN(slicedPath) || slicedPath === "") return; // catch invalid attempts

    ApiCall("GET", `locations?id=${slicedPath}`, null)
      .then((res) => {
        setLocation(res[0]);
        // console.log(res[0]);
      })
      .catch((error) => {
        if (
          /* error.response.status === 404 || */ error.response.status === 500
        )
          navigate("/");
        if (debug) console.log(error);
      });
  };

  useEffect(() => {
    if (locationPath.pathname === "/") {
      setLocation(null);
    }
    if (slicedPath.match(/^\d+$/)) {
      fetchApiData();
    }
  }, [locationPath]);

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            // minHeight: defaultHeight,
            overflowX: "hidden",
            minHeight: "calc(100vh - 80px)", // extra reserved for footer
          }}
        >
          <LocationInfo
            location={location}
            passLogout={handleLogout}
            passLogin={handleLogin}
            isLoading={loadingState}
          />
          <Box
            sx={{
              width: GetScreenWidth(),
              maxWidth: "1200px",
              height: "100dvh",
              margin: "auto",
              flex: 1,
            }}
          >
            <Router
              path={slicedPath}
              data={location}
              passLoadingState={changeLoadingState}
            />
          </Box>
        </Box>
        <div style={{ flex: 1 }} />
        <Footer />
      </Box>
    </>
  );
};

export default MainView;
