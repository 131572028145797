export function getCreatedDateDaysAgo(date) {
    const oneDay = 24 * 60 * 60;

    const now = new Date().getTime();
    const notif_date = new Date(date).getTime();

    const diffSeconds = Math.floor((now - notif_date) / 1000);

    const diffMinutes = Math.round(diffSeconds / 60);
    const diffHours = Math.round(diffSeconds / 3600);

    const diffDays = Math.round(diffSeconds / oneDay);

    if (diffMinutes < 60) return diffMinutes + " minuuttia sitten";

    if (diffHours < 24) return diffHours + " tuntia sitten";

    if (diffDays === 0) return "tänään";
    else if (diffDays === 1) return "eilen";
    else return diffDays + " päivää sitten";
}