import {
  CircularProgress,
  Collapse,
  Dialog,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../Utils/ApiContext";
import { ColorScheme } from "../../Utils/ColorScheme";

import { ReactComponent as ToggleArrow } from "../../Assets/NavigationArrowRight.svg";
import { ReactComponent as UserNameIcon } from "../../Assets/UserIcon.svg";
import { ReactComponent as PhoneIcon } from "../../Assets/OldPhoneIcon.svg";
import { GetScreenWidth } from "../../Utils/GetScreenWidth";

const inputStyle = {
  width: "100%",
  maxWidth: "550px",
  /* backgroundColor: "white", */
  border: "1px solid #D9D9D9",
  borderRadius: "4px",
  /* height: "50px", */
  marginTop: "20px",
  /* padding: "10px", */
  boxSizing: "border-box",
};

function UserInfoBox() {
  const { ApiCall } = useContext(ApiContext);

  const mobileWidth = GetScreenWidth() < 600;

  const [user, setUser] = useState(null);

  const [updating, setUpdating] = useState(false);
  const [open, setOpen] = useState(false);

  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);

  useEffect(() => {
    ApiCall("GET", "user")
      .then((user) => {
        setUser(user);
      })
      .catch(() => {
        setUser(null);
      });
  }, []);

  const handleChildClick = (event) => {
    event.stopPropagation();
  };

  const changedValue = (key, e) => {
    setUser({ ...user, [key]: e.target.value });
  };

  const modifyUser = (e) => {
    e.stopPropagation();
    setUpdating(true);
    ApiCall("PUT", `user/${user.user_id}`, {
      name: user.name,
      phone: user.phone,
    })
      .then(() => {
        setOpen(false);
        setUpdating(false);
        window.location.href = "/user";
      })
      .catch(() => {
        setUpdating(false);
      });
  };

  const deleteUser = (e) => {
    e.stopPropagation();

    setDeleteUserModalOpen(true);
  };

  const sendDeleteRequest = () => {
    ApiCall("DELETE", `user/${user.user_id}`)
      .then((res) => {
        document.cookie =
          "token-booking=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`";
        window.location.href = "/";
      })
      .catch((err) => {
        console.log(err);
        window.alert(
          "Tilin poistaminen epäonnistui. Jos ongelma toistuu, otathan yhteyttä support@tologo.fi"
        );
      });
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setDeleteUserModalOpen(false);
  };

  return (
    <>
      {user != null && (
        <div
          style={{
            backgroundColor: "#FAFAFA",
            border: "1px solid #D9D9D9",
            borderRadius: "4px",
            marginBottom: "20px",
          }}
        >
          {user != null && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "20px",
              }}
            >
              <div
                onClick={() => setOpen(!open)} // moved here to prevent accidental missclicks closing the collapse box
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
              >
                <div>
                  <Typography
                    variant="h6"
                    color="black"
                    style={{ fontWeight: "700", margin: "0px" }}
                  >
                    {user.name}
                  </Typography>
                  <Typography color="black" style={{ margin: "0px" }}>
                    {user.email}
                  </Typography>
                </div>
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    //border: "1px solid" + ColorScheme.veryLightGrey,
                    /*  boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.25)", */
                    borderRadius: "8px",
                    /* backgroundColor: getStatusColor(), */
                    alignSelf: "center",
                    marginLeft: "15px",
                    marginRight: "5px",
                    transformOrigin: "center",
                    transform: `rotate(${
                      open ? "90deg" : "0deg"
                    }) translateY(-50%)`,
                    transition: "transform 0.2s ease-in-out",
                  }}
                >
                  <ToggleArrow stroke="black" width="10px" height="10px" />
                </div>
              </div>

              <Collapse in={open}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: mobileWidth ? "column" : "row",
                    gap: mobileWidth ? "0px" : "20px",
                  }}
                >
                  <TextField
                    placeholder="Nimi"
                    onChange={(e) => changedValue("name", e)}
                    value={user.name}
                    sx={inputStyle}
                    InputProps={{
                      startAdornment: (
                        <UserNameIcon
                          stroke="black"
                          style={{ padding: "10px" }}
                        />
                      ),
                    }}
                    /* style={inputStyle} */ onClick={handleChildClick}
                  />
                  <TextField
                    placeholder="Puhelin"
                    value={user.phone}
                    style={inputStyle}
                    onClick={handleChildClick}
                    InputProps={{
                      startAdornment: (
                        <PhoneIcon stroke="black" style={{ padding: "10px" }} />
                      ),
                    }}
                    onChange={(e) => changedValue("phone", e)}
                  />
                </div>

                <div
                  onClick={(e) => modifyUser(e)}
                  style={{
                    backgroundColor: ColorScheme.tolotechBlue,
                    padding: "10px",
                    borderRadius: "4px",
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  {updating ? (
                    <CircularProgress size={"24px"} />
                  ) : (
                    <Typography
                      color="white"
                      style={{
                        margin: "0px",
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      Tallenna muutokset
                    </Typography>
                  )}
                </div>
                <div
                  onClick={(e) => deleteUser(e)}
                  style={{
                    alignSelf: "center",
                    padding: "10px",
                    borderRadius: "4px",
                    marginTop: "20px",
                    width: "150px",
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    color={ColorScheme.red}
                    style={{
                      margin: "0px",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Poista tili
                  </Typography>
                </div>
              </Collapse>
            </div>
          )}
        </div>
      )}
      <Dialog
        onClose={handleClose}
        open={deleteUserModalOpen}
        onClick={() => setDeleteUserModalOpen(false)}
      >
        <div
          style={{
            width: "85vw",
            maxWidth: "500px",
            flexDirection: "column",
            display: "flex",
            boxSizing: "border-box",
            padding: "20px",
          }}
        >
          <Typography
            variant="h6"
            style={{ fontWeight: "700", marginBottom: "20px" }}
          >
            Oletko varma, että haluat poistaa tilisi?
          </Typography>
          <Typography
            variant="h7"
            style={{ fontWeight: "500", marginBottom: "20px" }}
          >
            Tilin poistaminen poistaa tietosi, kulkuoikeutesi ja varauksesi.
          </Typography>
          <Typography variant="h7" style={{ fontWeight: "500" }}>
            Tätä toimintoa ei voi peruuttaa.
          </Typography>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              boxSizing: "border-box",
              flex: 1,
              marginTop: "20px",
            }}
          >
            <div
              onClick={() => setDeleteUserModalOpen(false)}
              style={{
                border: "1px solid " + ColorScheme.tolotechBlue,
                width: "48%",
                borderRadius: "4px",
              }}
            >
              <Typography
                style={{
                  padding: "10px",
                  textAlign: "center",
                  color: ColorScheme.tolotechBlue,
                  fontWeight: "700",
                  cursor: "pointer",
                }}
              >
                Peruuta
              </Typography>
            </div>
            <div
              style={{
                backgroundColor: ColorScheme.red,
                width: "48%",
                borderRadius: "4px",
              }}
              onClick={() => {
                const confirmed = window.confirm("Vahista tilin poistaminen");
                if (confirmed) {
                  sendDeleteRequest();
                }
              }}
            >
              <Typography
                style={{
                  padding: "10px",
                  textAlign: "center",
                  fontWeight: "700",
                  cursor: "pointer",
                  color: "white",
                }}
              >
                Vahvista
              </Typography>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default UserInfoBox;
