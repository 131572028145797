import { Button, Typography } from "@mui/material";
import React, { useContext } from "react";
import { UserContext } from "../../Utils/UserContext";
import { useNavigate } from "react-router-dom";

export const EmptyPage = () => {
  const { isLogged } = useContext(UserContext);
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "100px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
        <div style={{ height: "40px" }}/>
      <div
        style={{ display: "inherit", flexDirection: "inherit", gap: "10px" }}
      >
        {!isLogged ? (
          <Typography>
            Varmistathan, että olet kirjautunut sisään nähdäksesi kaiken
            sisällön.
          </Typography>
        ) : null}
        <Typography>Täältä ei löytynyt mitään :(</Typography>
      </div>

      <Button
        onClick={() => handleRedirect()}
        variant="contained"
        sx={{
          width: "200px",
          height: "60px",
        }}
      >
        Etusivulle
      </Button>
    </div>
  );
};
