import React, { useState } from "react";
import { ColorScheme } from "../../Utils/ColorScheme";
import { Typography } from "@mui/material";

export const ButtonBase = ({ handleClick, text, redirectTo }) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => handleClick(redirectTo)}
      style={{
        height: "40px",
        width: "100%",
        backgroundColor: hover
          ? ColorScheme.tolotechDarkBlue
          : ColorScheme.tolotechBlue,
        borderRadius: "5px",
        color: ColorScheme.tolotechWhite,
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
        border: "1px solid transparent", // fix to flickering
        filter: "opacity(100%)",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        cursor: "pointer",
      }}
    >
      <Typography fontWeight="700" fontSize="12px">
        {text}
      </Typography>
    </div>
  );
};
