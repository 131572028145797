import { Box, Typography } from "@mui/material";
import React, { memo } from "react";

const FieldSlotCard = ({
  slot,
  i,
  canBeSelected,
  reservationClicked,
  field,
  calculatePaddingFromTop,
  getLengthInMinutes,
  smallestGap,
  slotHeight,
  colorSwitch,
}) => {
  return (
    <Box
      /* key={i} */
      onClick={() => canBeSelected(slot) && reservationClicked(slot, field)}
      style={{
        position: "absolute",
        top: calculatePaddingFromTop(new Date(slot.from_time)),
        width: "100%",
        left: 0,
        height: (getLengthInMinutes(slot) / smallestGap) * slotHeight - 2,
        backgroundColor: colorSwitch(slot),
        borderRadius: "4px",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {slot.user_limit > 1 ? (
        <Typography fontSize="12px" fontWeight="700">
          {slot.user_limit - slot.availability}/{slot.user_limit}
        </Typography>
      ) : undefined}

      <Typography fontSize="12px" fontWeight="700">
        {slot.vat_price} €
      </Typography>
    </Box>
  );
};

export default memo(FieldSlotCard);