import React, { memo } from "react";
import { Box, Typography } from "@mui/material";
import { ColorScheme } from "../../Utils/ColorScheme";

const TimeArrayBackground = ({
  slot,
  first,
  i,
  minimum_reservation,
  reservables,
  slotHeight,
  minWidth,
}) => {
  function getTimeStamp(time) {
    const hours = Math.floor(time / 60);
    let minutes = time % 60;
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    return hours + ":" + minutes;
  }

  return (
    <Box
      key={i}
      sx={{
        top: slotHeight * ((slot - first) / minimum_reservation),
        position: "relative",
        pointerEvents: "none",
        zIndex: 1,
        // width: "100%",
        left: 0,
        height: 0,
      }}
    >
      <Box
        sx={{
          //  width: "100%",
          height: "5px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "silver",
            fontSize: "12px",
            marginRight: "10px",
            width: "30px",
            //textAlign: "center",
            userSelect: "none",
          }}
        >
          {getTimeStamp(slot)}
        </Typography>

        <div
          style={{
            position: "relative",
            left: "5px",
            bottom: "2px",
            color: ColorScheme.veryLightGrey,
            fontSize: "36px",
            userSelect: "none",
          }}
        >
          &#x2022;
        </div>
        <Box
          sx={[
            {
              position: "relative",
              height: "1px",
              backgroundColor: ColorScheme.veryLightGrey,
              width: reservables.length * minWidth + "px",
              minWidth: "calc(100% - 50px)",
            },
          ]}
        />
      </Box>
    </Box>
  );
};

export default memo(TimeArrayBackground);
