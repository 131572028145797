import { Alert, Collapse, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { ColorScheme } from "../../Utils/ColorScheme";

export const GDPRBanner = () => {
  const [showGDPR, setShowGDPR] = useState(true);
  const [agreed, setAgreed] = useState(
    sessionStorage.getItem("Tolotech-web-booking-gdpr") === "true"
  );

  const handleAgree = () => {
    sessionStorage.setItem("Tolotech-web-booking-gdpr", "true");
    setAgreed(true);
    setShowGDPR(false);
  };

  useEffect(() => {
    const existingToken = document.cookie.includes("token-booking");
    if (existingToken) {
      handleAgree();
    }
  }, []);

  if (agreed) {
    return null;
  }

  return (
    <Collapse
      in={showGDPR}
      sx={{
        textAlign: "center",
      }}
    >
      <Alert
        severity="info"
        variant="filled"
        onClose={() => {
          handleAgree();
        }}
        closeText="Sulje"
        sx={{
          backgroundColor: ColorScheme.tolotechBlue,
          filter: "opacity(100%)",
          position: "fixed",
          bottom: 10,
          left: 10,
          right: 10,
          zIndex: 9999,
          height: "75px",
          margin: "auto",
          paddingBottom: "15px",
          width: isMobile ? "80dvw" : "35vw",
        }}
      >
        <Typography>
          Sivustomme käyttää evästeitä rekisteröityneen käyttäjän
          tunnistamiseen.
        </Typography>
      </Alert>
    </Collapse>
  );
};
