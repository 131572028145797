import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ColorScheme } from "../../Utils/ColorScheme";
import { ReactComponent as ArrowBack } from "../../Assets/arrowBack.svg";

export const ToFrontpage = ({ path }) => {
  const navigate = useNavigate();
  return (
    <Box
      onClick={(event) => {
        event.stopPropagation();
        navigate(path ? path : "/"); // If path is defined we will use it.
      }}
      sx={{
        height: "35px",
        width: "35px",
        maxWidth: "35px",
        fontSize: "12px",
        fontWeight: "600",
        marginRight: "15px",
        borderRadius: "40px",
        color: ColorScheme.black,
        filter: "opacity(100%)",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flex: 1,
        zIndex: 1,
        backgroundColor: "#7C97B0",
      }}
    >
      <ArrowBack width="16px" height="16px" />
    </Box>
  );
};
