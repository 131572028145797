import React from "react";
import ReactDOM from "react-dom/client";
import "./Styles/index.css";
import App from "./App";
//import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./Utils/Theme";
import { fiFI, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { fi } from "date-fns/locale";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      localeText={
        fiFI.components.MuiLocalizationProvider.defaultProps.localeText
      }
      adapterLocale={fi}
    >
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </LocalizationProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
