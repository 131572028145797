import { createTheme } from "@mui/material";
import { ColorScheme } from "./ColorScheme";

// Google fonts api
const fontUrl =
  "https://fonts.gstatic.com/s/sofiasans/v16/Yq6R-LCVXSLy9uPBwlATrOF6kjouQb4.woff2"; // sofia-sans latin font url, if fonts are messed, check this url and update if needed
const fontWeights = [100, 200, 300, 400, 500, 600, 700, 800, 900]; // font weights to load
const fontPromises = [];

fontWeights.forEach((weight) => {
  const font = new FontFace("Sofia Sans", `url(${fontUrl}) format('woff2')`, {
    weight: `${weight}`,
  });
  fontPromises.push(font.load());
});

export const theme = createTheme({
  root: {
    "&::-webkit-scrollbar": {
      display: "none",
      "-ms-overflow-style": "none" /* IE and Edge */,
      "scrollbar-width": "none" /* Firefox */,
      width: "0px",
    },
    /* "&::-webkit-scrollbar-track": {
			boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)"
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "darkgrey",
			outline: "1px solid slategrey"
		}*/
  },
  palette: {
    action: { hover: "#FFFFFF", selected: "#FFFFFF" },
  },
  typography: {
    fontFamily: ["Sofia sans"],
    locationCard: {
      // variantti ID
      color: "#000000",
    },
  },

  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1.2em",
          color: "white",
          padding: "15px",
          backgroundColor: ColorScheme.tolotechDarkBlue,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // komponentissa määritelty className: searchBar
          "&.searchBar": {
            width: "50%",
            alignSelf: "center",
            color: "white",

            "& label": {
              color: "white",
            },
            "& input": {
              color: "white",
            },
            "&:hover label": {
              fontWeight: 700,
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "gray",
            },
            "&:hover .MuiInput-underline:before": {
              borderBottomColor: "gray",
            },
            "&:not(hover) .MuiInput-underline:before": {
              borderBottomColor: "gray",
            },
            "& label.Mui-focused": {
              color: "white",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "white",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white",
                backgroundColor: ColorScheme.tolotechDarkBlue,
              },
              "&:hover fieldset": {
                borderColor: "white",
                borderWidth: 2,
                backgroundColor: ColorScheme.tolotechLightBlue,
              },
              "&.Mui-focused fieldset": {
                borderColor: "white",
                backgroundColor: ColorScheme.tolotechLightBlue,
              },
            },
            "& .MuiOutlinedInput-input + fieldset": {
              backgroundColor: "none",
              color: "red",
            },
          },
        },
        test: {
          color: "#fff",
          backgroundColor: "yellow",
          width: "200px",
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: ["Sofia Sans"],
          /* fontWeight: "400",
					fontStyle: "normal" */
        },
        defaultBlack: {
          fontSize: "24px",
          color: "#000000",
          fontWeight: "700",
        },
        time: {
          fontSize: "12px",
          color: "#000000",
          fontWeight: "700",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        /* root: {
					"& input[type=number]": {
						MozAppearance: "textfield"
					},
					"& input[type=number]::-webkit-outer-spin-button": {
						WebkitAppearance: "none",
						margin: 0
					},
					"& input[type=number]::-webkit-inner-spin-button": {
						WebkitAppearance: "none",
						margin: 0
					}
				},*/
        colorPrimary: {
          backgroundColor: "#FFFFFF",
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#ECEFF2",
          color: "#000000",
        },
      },
    },
    modal: {
      "&:focus": {
        border: "none",
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          color: "#000000",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderStyle: "dashed",
          borderColor: "rgba(255, 255, 255, 0.2)",
        },
        locationForm: {
          borderStyle: "solid",
          borderColor: ColorScheme.tolotechDarkBlue,
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        item: {},
        smallPreviews: {
          // todo not working
          backgroundColor: "#fff",
          height: "150px",
          opacity: "50%",
          "&:hover": {
            "&>a": {
              color: "green",
            },
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
          "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 2,
            transitionDuration: "300ms",
            "&.Mui-checked": {
              transform: "translateX(16px)",
              color: "#fff",
              "& + .MuiSwitch-track": {
                backgroundColor: ColorScheme.tolotechBlue,
                opacity: 1,
                border: 0,
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
              },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
              color: "#33cf4d",
              border: "6px solid #fff",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              color: ColorScheme.tolotechDarkBlue,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.3,
            },
          },
          "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 22,
            height: 22,
          },
          "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            backgroundColor: "grey",
            opacity: 1,
            transition:
              (["background-color"],
              {
                duration: 500,
              }),
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: ColorScheme.tolotechBlue,
          height: "5%",
          width: "50%", // todo ei mielellään kiinteitä pikseliarvoja
          "&:hover": {
            backgroundColor: ColorScheme.tolotechDarkBlue,
          },
          fontSize: "12px",
          textTransform: "none",
        },
        outlined: {
          color: "white",
          border: `2px solid ${ColorScheme.blueOpacity}`,
          fontSize: "12px",
          textTransform: "none",
          height: "40px",
          padding: "5px",
        },
        testings: {
          right: "10px",
          px: "35px",
          textTransform: "none",
          backgroundColor: "#FFFFFF",
          "&:hover": {
            color: ColorScheme.tolotechWhite,
            border: `1px solid ${ColorScheme.tolotechWhite}`,
          },
        },
        logoUpload: {
          color: "#000000",
          backgroundColor: ColorScheme.tolotechLightBlue,
          border: "3px dashed #000000",
          minWidth: "40px",
        },
        bannerUpload: {
          color: "#000000",
          backgroundColor: ColorScheme.tolotechLightBlue,
          border: "3px dashed #000000",
          width: "500px",
          height: "200px",
        },
        productUpload: {},
        deleteButton: {
          color: ColorScheme.tolotechWhite,
          backgroundColor: ColorScheme.tolotechBlue,
          height: "5%",
          width: "5%", // todo ei mielellään kiinteitä pikseliarvoja
          "&:hover": {
            backgroundColor: ColorScheme.tolotechDarkBlue,
          },
          fontSize: "12px",
          textTransform: "none",
        },
        drawerFormAccept: {
          backgroundColor: ColorScheme.tolotechBlue,
          color: ColorScheme.tolotechWhite,
          height: "46px",
          "&:hover": {
            backgroundColor: ColorScheme.tolotechDarkBlue,
          },
          fontSize: "12px",
          textTransform: "none",
        },

        drawerFormReject: {
          border: "1px solid #1C568F",
          height: "46px",
          "&:hover": {
            backgroundColor: ColorScheme.tolotechDarkBlue,
          },

          fontSize: "12px",
          textTransform: "none",
        },
        drawerFormCornerClose: {
          border: "1px solid #1C568F",
          borderRadius: "25px",
          height: "46px",
          width: "26px",
          "&:hover": {
            backgroundColor: ColorScheme.tolotechDarkBlue,
          },

          fontSize: "12px",
          textTransform: "none",
        },
        smallAccept: {
          backgroundColor: ColorScheme.tolotechBlue,
          color: ColorScheme.tolotechWhite,
          height: "16px",
          border: "1px solid transparent",
          "&:hover": {
            backgroundColor: ColorScheme.tolotechWhite,
            color: ColorScheme.tolotechDarkBlue,
            border: `1px solid ${ColorScheme.tolotechDarkBlue}`,
          },
          fontSize: "10px",
          textTransform: "none",
        },
        smallReject: {
          backgroundColor: ColorScheme.tolotechWhite,
          color: ColorScheme.tolotechBlue,
          height: "16px",
          "&:hover": {
            color: ColorScheme.tolotechWhite,
            backgroundColor: ColorScheme.tolotechDarkBlue,
          },
          fontSize: "10px",
          textTransform: "none",
        },
        bookingButton: {
          backgroundColor: ColorScheme.tolotechBlue,
          color: ColorScheme.tolotechWhite,
          height: "46px",
          "&:hover": {
            color: ColorScheme.tolotechWhite,
            backgroundColor: ColorScheme.tolotechAlt,
          },
          fontSize: "10px",
          textTransform: "none",
        },
      },
    },
  },
});
