export const ColorScheme = {
  bg: "#1E2D43",
  tolotechBlue: "#1C568F",
  tolotechDarkBlue: "#264166",
  tolotechLightBlue: "#6281AB",
  tolotechWhite: "rgba(255,255,255,1)",
  tolotechGray: "rgba(255,255,255,0.5)",
  green: "#60CBB1",
  select_green: "#2F9C1D",
  darkGreen: "#508A35",
  red: "#C22020",
  purple: "#995CB6",
  softRed: "#CD8B8B",
  yellow: "#FFBD33",
  tolotechAlt: "#48526B",
  blackOpacity: "rgba(0,0,0,0.2)",
  blueOpacity: "rgba(98, 129, 171, 0.1)",
  black: "rgb(34,34,34)",
  grey: "#7C97B0",
  lightGrey: "#DCDCDC",
  veryLightGrey: "#F0F0F0",
  blue: "#296DAE"
  
};
