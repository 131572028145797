import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Cross } from "../../Assets/icon-cross.svg";
import { ColorScheme } from "../../Utils/ColorScheme";

export const PaymentCancel = ({ path }) => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(`/`);
  };

  return (
    <Stack
      spacing={6}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      sx={{ padding: "25%" }}
    >
      <Box sx={{height: "100px", width: "100px", border: "5px solid " + ColorScheme.red, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "100px"}}>
      <Cross width="60px" height="60px" strokeWidth={"5px"} stroke={ColorScheme.red} />
      </Box>
      <Typography sx={{fontWeight: "700"}}>Maksutapahtuma peruuntui, yritä uudelleen!</Typography>
      <Button
        variant="contained"
        onClick={() => handleRedirect()}
        sx={{ padding: "10px", borderRadius: "4px", boxShadow: "none" }}
      >
        Palaa takaisin
      </Button>
    </Stack>
  );
};
