import React, { useContext, useState } from "react";
import { ColorScheme } from "../../Utils/ColorScheme";
import { OrderControl } from "./OrderControl";
import { CircularProgress, Typography } from "@mui/material";
import { ApiContext } from "../../Utils/ApiContext";

export const OrderContent = ({ order, locations }) => {

  const { ApiCall } = useContext(ApiContext);
  /* const getContentLabel = (value) => {
    // todo lisää puuttuvat arvot
    switch (value) {
      case 2:
        return "Tilavaraukset";

      case 3:
        return "Tuotteet";

      case 4:
        return "Kurssit";
      default:
        break;
    }
  }; */

  const [sendingReceipt, setSendingReceipt] = useState(false);

  const getStatusColor = () => {
    if (order.order_succesful && order.refund_status !== 1) {
      return ColorScheme.select_green;
    }

    if (order.refund_status === 1) {
      return ColorScheme.purple;
    }

    if (order.refund_status !== 1 && !order.order_succesful) {
      return ColorScheme.softRed;
    }
  };

  const calculateCancelPeriod = (value) => {
    const findLocation = locations.find(
      (entry) => entry.location_id === value.location_id
    );

    const startingTime = new Date(value.starting_time);
    const reducedTime = new Date(startingTime);
    const currentTime = new Date();
    const cancelPeriod = findLocation?.withdrawal_time || 0; // if not found use zero hour buffer

    reducedTime.setHours(reducedTime.getHours() - cancelPeriod);

    if (currentTime < reducedTime) {
      return reducedTime;
    } else return null;
  };

  const canCancel = () => calculateCancelPeriod(order);
  // console.log(canCancel());
  // console.log(Boolean(canCancel()));

  const getStatusText = () => {
    if (order.order_succesful && order.refund_status !== 1) {
      return "Maksettu";
    }

    if (order.refund_status === 1) {
      return "Maksu peruutettu";
    }

    if (order.refund_status !== 1 && !order.order_succesful) {
      return undefined;
    }
  };

  const getTotal = () => {
    return Number(
      order.order_contents
        .map((entry) => entry.vat_price)
        .reduce((prev, current) => prev + current, 0)
    ).toFixed(2).replace(".", ",");
  };

  const sendOrderReceipt = (order_id) => {
    setSendingReceipt(true);
    ApiCall("GET", `user/order/receipt/${order_id}`, null)
      .then((response) => {
        console.log(response);
        setSendingReceipt(false);
      }).catch((error) => {
        console.log(error);
        setSendingReceipt(false);
      })
  }

  return (
    <div
      style={{
        /* backgroundColor: "white", */
        padding: "20px",
        /*  borderTop: "1px solid " + ColorScheme.blackOpacity, */
        borderRadius: "4px",
        /*  boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.05)", */
        overflow: "hidden",
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "4px",
          padding: "20px",
          border: "1px solid " + ColorScheme.veryLightGrey,
        }}
      >
        {order?.balance_transactions.length === 1 &&
          Math.sign(order.balance_transactions[0].amount) === -1 && ( // if balance transaction is negative
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                /* justifyContent: "space-between", */
                paddingRight: "20px",
                gap: "5px",
                paddingBottom: "15px",
                marginBottom: "15px",
                borderBottom: "1px solid " + ColorScheme.veryLightGrey,
              }}
            >
              <Typography fontWeight={700}>Saldoa käytetty</Typography>
              <div
                style={{
                  flex: 1,
                 /*  borderBottom: "dotted 1px" + ColorScheme.veryLightGrey, */
                   /* marginLeft: "3%",
                  marginRight: "3%",  */
                }}
              />
              <Typography fontWeight={700} fontSize={16}>
                {parseFloat(
                  Number(order?.balance_transactions[0]?.amount).toFixed(2).replace(".", ",")
                )}{" "}
                €
              </Typography>
            </div>
          )}
        {Boolean(order.order_contents) &&
          order.order_contents.map((entry, i) => {
            return (
              <div
                key={i}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "5px",
                  alignItems: "center",
                }}
              >
                {/* <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                }}
              >
                <Typography fontWeight={800}>
                  {checkFirst(entry.content_type) &&
                    getContentLabel(entry.content_type)}
                </Typography>
                <div style={{ flex: 1 }} />
              </div> */}
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    alignItems: "baseline",
                    gap: "5px"
                  }}
                >
                  <div style={{ fontSize: "16px" }}>{entry.name}</div>
                  <div
                    style={{
                      flex: 1,
                      borderBottom: "dotted 1px" + ColorScheme.veryLightGrey,
                      minWidth: "100px",
                      /* marginLeft: "3%",
                      marginRight: "3%", */
                    }}
                  />
                  <div
                    style={{
                     /*  minWidth: "25%", */
                      textAlign: "right",
                      paddingRight: "20px",
                    }}
                  >
                    <div style={{ fontSize: "16px", fontWeight: 500, whiteSpace: "nowrap" }}>
                      {Number(entry.vat_price).toFixed(2).replace(".",",")} €
                    </div>
                    <div style={{ fontSize: "10px", color: "#A6A6A6" }}>
                      alv. {entry.vat_percentage}%
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderTop: "1px solid" + ColorScheme.veryLightGrey,
            marginTop: "15px",
            paddingRight: "20px",
          }}
        >
          <Typography
            style={{
              textAlign: "right",
              fontSize: "16px",
              paddingTop: "15px",
              fontWeight: 700,
            }}
          >
            Yhteensä
          </Typography>
          <Typography
            fontWeight={700}
            style={{ textAlign: "right", fontSize: "16px", paddingTop: "15px" }}
          >
            {getTotal()} €
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyItems: "center",
            justifyContent: "flex-end",
            marginTop: "15px",
            gap: "15px",
            paddingRight: "20px",
          }}
        >
          {(!Boolean(canCancel()) ||
            order.refund_status ===
            1) /* Checks if order doesn't have time left for cancellation, or has refunded status 1 with time left for cancellation */ && (
              <Typography
                fontSize="14px"
                color={getStatusColor()}
                fontWeight={700}
              >
                {getStatusText()}
              </Typography>
            )}

          {(Boolean(canCancel()) || !order.order_succesful) &&
            order.refund_status !== 1 && (
              <OrderControl order={order} canCancel={canCancel()} />
            )}
        </div>
        {order.order_succesful && order.refund_status !== 1 && (
          <div
            onClick={(e) => {
              e.stopPropagation(); // prevent parent element closing
              sendOrderReceipt(order.order_id)
            }}
            style={{
              width: "100%",
              height: "41px", // keep same height for load animation                               
              maxWidth: "300px",
              backgroundColor: ColorScheme.tolotechBlue,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              padding: "10px",
              boxSizing: "border-box",
              marginTop: "15px",
              borderRadius: "4px",
            }}
          >
            {sendingReceipt ?
              <CircularProgress
                style={{ color: ColorScheme.tolotechWhite }}
                size="1.5rem"
              />
              : <Typography fontWeight={700} fontSize="14px" color={ColorScheme.tolotechWhite}>
                Lähetä kuitti sähköpostiin
              </Typography>
            }
          </div>
        )}
      </div>
    </div>
  );
};
