import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../Utils/ApiContext";
import { OrderCard } from "../Orders/OrderCard";
import { LoadMoreButton } from "./LoadMoreButton";

export const UserReservations = ({ allLocations }) => {
  const { ApiCall } = useContext(ApiContext);

  const [isLoading, setIsLoading] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [loadMore, setLoadMore] = useState(false);

  const fetchReservations = (page) => {
    setIsLoading(true);
    ApiCall("GET", `user/get_reservations?page=${page || 1}`, null)
      .then((response) => {
        if (response.length > 9) {
          setLoadMore(true);
        } else {
          setLoadMore(false);
        }

        if (pageIndex > 1 && response.length === 0) {
          setPageIndex(pageIndex - 1);
        } else {
          setPageIndex(page || 1);
          setReservations((prevReservations) => [
            ...prevReservations,
            ...response,
          ]);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  const handleLoadMore = (value) => {
    fetchReservations(value);
  };

  useEffect(() => {
    setReservations([]);
    fetchReservations();
  }, []);

  return (
    <div
      style={{
        minHeight: "200px",
        display: "flex",
        flexDirection: "column",
        borderRadius: "4px",
        /* border: "1px solid #D9D9D9", */
        transition: "all 0.3s ease",
        overflow: "hidden",
        /* backgroundColor: "#fafafa", */
        // gap: "20px",
      }}
    >
      {isLoading && reservations.length === 0 ? (
        <div
          style={{
            padding: "40px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "40px",
          }}
        >
          <CircularProgress />
          <Typography>Ladataan varaustietoja ...</Typography>
        </div>
      ) : reservations.length > 0 ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Box
            sx={{
              border: "1px solid #D9D9D9",
              borderRadius: "4px",
              overflow: "hidden",
            }}
          >
            {reservations.map((entry, i) => {
              return (
                <OrderCard
                  key={i}
                  order={entry}
                  index={i}
                  start={new Date(entry.from_time)}
                  end={new Date(entry.to_time)}
                  locations={allLocations}
                  isLoading={isLoading}
                />
              );
            })}
          </Box>

          <LoadMoreButton
            {...{ handleLoadMore, pageIndex, loadMore, isLoading }}
          />
        </Box>
      ) : (
        <div
          style={{
            padding: "40px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "40px",
          }}
        >
          <Typography textAlign="center">
            Yhtään tilausta ei ole vielä tehty tällä tilillä.
          </Typography>
        </div>
      )}
    </div>
  );
};
