 import { useEffect, useRef } from "react";

/**
 * Custom hook for enabling horizontal scrolling on a DOM element.
 * @returns {React.RefObject} A ref object that should be attached to the DOM element.
 */

export function useHorizontalScroll() {
  // Create a ref object to store the DOM element
  const elRef = useRef();
  // Create ref objects to store the initial touch position and scroll position
  const startX = useRef();
  // Create a ref object to store the initial scroll position
  const scrollLeft = useRef();

  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onTouchStart = (e) => {
        // Get the initial touch position and scroll position
        const touch = e.touches[0];
        startX.current = touch.pageX - el.offsetLeft;
        scrollLeft.current = el.scrollLeft;
      };

      const onTouchMove = (e) => {
        // Calculate the new scroll position based on the initial touch position
        e.preventDefault();
        const touch = e.touches[0];
        const x = touch.pageX - el.offsetLeft;
        const walk = (x - startX.current);
        el.scrollLeft = scrollLeft.current - walk;
      };

      // Add event listeners for touch events
      el.addEventListener("touchstart", onTouchStart, { passive: true });
      el.addEventListener("touchmove", onTouchMove, { passive: false });

      return () => {
        // Remove event listeners when the component is unmounted
        el.removeEventListener("touchstart", onTouchStart);
        el.removeEventListener("touchmove", onTouchMove);
      };
    }
  }, []);

  return elRef;
}