//import logo from "./logo.svg";

import MainView from "./Components/Main/MainView";
import { ApiProvider } from "./Utils/ApiContext";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./Utils/UserContext";

function App() {
  return (
    <UserProvider>
      <BrowserRouter>
        <ApiProvider>
          <MainView />
        </ApiProvider>
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;
